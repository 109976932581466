import { useFlag, useFlags, useAtLeastOneFlag } from './use-flag'
import { FF } from './flags'

const isValueTrue = (value: boolean) => value === true
const isValueFalse = (value: boolean) => value === false

export { useFlag, useFlags, useAtLeastOneFlag, FF }

// This should be rewritten to not use properties on functional components if possible
interface AnonFlagComponent {
  (args: { name: string; defaultValue: boolean; children: any[] }):
    | any[]
    | false
  displayName: string
}

type GetFlagComponentFactory = (args: {
  testFlagValue: (value: any) => boolean
  displayName: string
}) => AnonFlagComponent

const getFlagComponent: GetFlagComponentFactory = ({
  testFlagValue,
  displayName
}) => {
  const AnonComponent: AnonFlagComponent = ({
    name,
    defaultValue,
    children
  }) => {
    const value = useFlag(name, defaultValue)
    return testFlagValue(value) && children
  }
  AnonComponent.displayName = displayName
  return AnonComponent
}

export const FlagOn = getFlagComponent({
  testFlagValue: isValueTrue,
  displayName: 'FlagOn'
})

export const FlagOff = getFlagComponent({
  testFlagValue: isValueFalse,
  displayName: 'FlagOff'
})

interface AnonFlagWrapperComponent {
  (args: {
    name: string
    defaultValue: boolean
    wrapper: (component: any) => any
    children: any[] | Function
  }): any[] | false
  displayName: string
}

type GetFlagWrapperComponentFactory = (args: {
  testFlagValue: (value: any) => boolean
  displayName: string
}) => AnonFlagWrapperComponent

const getFlagWrapperComponent: GetFlagWrapperComponentFactory = ({
  testFlagValue,
  displayName
}) => {
  const AnonComponent: AnonFlagWrapperComponent = ({
    name,
    defaultValue,
    wrapper,
    children
  }) => {
    const value = useFlag(name, defaultValue)
    if (value === null) return null
    if (testFlagValue(value)) {
      return wrapper(children)
    }
    return typeof children === 'function' ? children() : children
  }
  AnonComponent.displayName = displayName
  return AnonComponent
}

export const WrapIfFlagOn = getFlagWrapperComponent({
  testFlagValue: isValueTrue,
  displayName: 'WrapIfFlagOn'
})

export const WrapIfFlagOff = getFlagWrapperComponent({
  testFlagValue: isValueFalse,
  displayName: 'WrapIfFlagOff'
})
