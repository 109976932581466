import { useQuery } from '@apollo/client'
import { DO_MENU_ITEM_DETAILS } from '../graphql'
import { VisibilityEnum } from '../types'

// TODO: Autogenerate these from graphql schema
interface Input {
  restaurantGuid: string
  itemGuid: string
  itemGroupGuid: string
  queryOptions: Object
  visibility: VisibilityEnum
  dateTime?: string
}

const useDoMenusItemDetailsQuery = ({
  restaurantGuid,
  itemGuid,
  itemGroupGuid,
  queryOptions,
  visibility = 'TOAST_ONLINE_ORDERING',
  dateTime
}: Input) => {
  const { loading, error, data, called } = useQuery(DO_MENU_ITEM_DETAILS, {
    notifyOnNetworkStatusChange: true,
    // no cache instead of cache-and-network
    // because of: https://github.com/apollographql/react-apollo/issues/3361
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        restaurantGuid,
        menuItemGuid: itemGuid,
        menuGroupGuid: itemGroupGuid,
        visibility,
        dateTime: dateTime || undefined
      }
    },
    ...queryOptions
  })
  return { loading, error, data, called }
}

export { useDoMenusItemDetailsQuery }
