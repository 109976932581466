import React from 'react'
import cx from 'classnames'
import { getIn, useFormikContext } from 'formik'

import { Fieldset } from '@local/do-secundo-fieldset'
import { isNumeric } from '@local/modifier-form-helpers'

import styles from './ModifierFieldset.module.css'
import { Dollars } from '@local/do-secundo-dollars'

/**
 * Returns # of items selected for a single select or total quantity for checkboxes/multiboxes
 * @param {boolean} flagEnabled
 * @param {array} value
 * @param {boolean} isModifierSingleSelect - used to differentiate between radio vs checkbox/multibox
 * @returns {number|*}
 */
const getQuantity = (value: object, isSingleSelect: boolean = false) => {
  if (!Array.isArray(value)) return 0
  if (!isSingleSelect) {
    return value.reduce((acc, { quantity }) => acc + quantity, 0)
  }
  return value.filter(({ selected }) => selected).length
}

interface InstructionsProps {
  minSelections: number
  maxSelections: number | null
  quantity: number | boolean
  modifierGroupPrice: number
  isSequencePricing: boolean
}

const Instructions = ({
  minSelections,
  maxSelections,
  quantity,
  modifierGroupPrice,
  isSequencePricing
}: InstructionsProps) => {
  const isExactSelection =
    isNumeric(minSelections) &&
    isNumeric(maxSelections) &&
    minSelections === maxSelections

  let message
  if (isExactSelection) {
    message = `Please choose ${minSelections}`
  } else if (minSelections && maxSelections) {
    message = `Please choose ${minSelections} and up to ${maxSelections}`
  } else if (minSelections) {
    message = `Please choose ${minSelections} or more`
  } else if (maxSelections) {
    message = `Please choose up to ${maxSelections}`
  }

  const showSelected = Boolean(quantity && maxSelections)
  if (message || isSequencePricing) {
    return (
      <div className={styles.instructions}>
        {message && (
          <p className='type-default'>
            {message}
            {showSelected && !isSequencePricing && (
              <strong className={cx(styles.quantity, 'type-default')}>
                {` (${quantity} selected)`}
              </strong>
            )}
          </p>
        )}
        {isSequencePricing && (
          <div className={styles.modifierGroupPrice}>
            {quantity > 0 ? (
              <span className={styles.selected}>
                {`${quantity} selected, `}
                <Dollars
                  amount={modifierGroupPrice}
                  renderWhenZero={'no additional cost'}
                  showLeadingSign
                />
              </span>
            ) : (
              <span>Extra costs may apply</span>
            )}
          </div>
        )}
      </div>
    )
  }
  return null
}

export interface ModifierFieldsetProps {
  label: string
  children: React.ReactNode
  rules: {
    // requirements defined in do-secundo-model/src/ModifierGroup.js > ModifierGroupShape
    minSelections: number
    maxSelections: number | null
    pricingMode: string
  }
  name: string
  isSingleSelect: boolean
  modifierGroupPrice: number
  isSequencePricing: boolean
}

export const ModifierFieldset = ({
  label,
  children,
  rules = {
    minSelections: 0,
    maxSelections: 0,
    pricingMode: ''
  },
  name,
  isSingleSelect,
  modifierGroupPrice,
  isSequencePricing
}: ModifierFieldsetProps) => {
  const formik = useFormikContext()
  const value = getIn(formik.values, name)
  const quantity = getQuantity(value, isSingleSelect)
  const { minSelections, pricingMode } = rules
  const isRequired = minSelections >= 1 || pricingMode === 'REPLACES_PRICE'

  return (
    <Fieldset
      label={label}
      children={children}
      required={isRequired}
      collapsable
      instructions={
        <Instructions
          {...rules}
          quantity={quantity}
          modifierGroupPrice={modifierGroupPrice}
          isSequencePricing={isSequencePricing}
        />
      }
      name={name}
    />
  )
}
