import PropTypes from 'prop-types'
import { Model } from './Model'
import { ModifierGroup, ModifierGroupModel } from './ModifierGroup'

export interface MenuItemDetailsModel {
  description?: string
  name: string
  guid: string
  calories?: number
  price?: number
  imageUrl?: string
  usesFractionalQuantity?: boolean
  unitOfMeasure: string
  modifierGroups: ModifierGroupModel[]
}

export class MenuItemDetails extends Model implements MenuItemDetailsModel {
  modifierGroups: ModifierGroup[]
  name: string
  guid: string
  calories?: number
  price?: number
  imageUrl?: string
  usesFractionalQuantity?: boolean
  unitOfMeasure: string

  constructor(menuItemDetails: MenuItemDetailsModel) {
    super(menuItemDetails)
    this.modifierGroups = this.mapModifierGroups(
      menuItemDetails.modifierGroups || []
    )

    this.name = menuItemDetails.name
    this.guid = menuItemDetails.guid
    this.unitOfMeasure = menuItemDetails.unitOfMeasure
  }

  mapModifierGroups(modifierGroups: ModifierGroupModel[]) {
    return modifierGroups.map(
      (modifierGroup) => new ModifierGroup(modifierGroup, this)
    )
  }

  getNextValues(): ModifierGroupModel[] {
    return this.modifierGroups
  }
}

// some js files still need this
export const MenuItemDetailsShape = PropTypes.shape({
  description: PropTypes.string,
  name: PropTypes.string,
  guid: PropTypes.string.isRequired,
  calories: PropTypes.number,
  price: PropTypes.number,
  imageUrl: PropTypes.string,
  usesFractionalQuantity: PropTypes.bool,
  unitOfMeasure: PropTypes.string.isRequired,
  modifierGroups: PropTypes.array.isRequired
})
