import React, { MouseEvent, MouseEventHandler } from 'react'
import ReactDOM from 'react-dom'
import KeyHandler from 'react-key-handler'
import cx from 'classnames'

import { ErrorComponent } from '@local/error'
import { CloseButton } from '@local/do-secundo-closebutton'

import styles from './Modal.module.css'
import { FloatingOverlay } from '@floating-ui/react'
let modalCount = 0
let modalRoot = document.getElementById('modal-root')
if (!modalRoot) {
  modalRoot = document.createElement('div')
  modalRoot.id = 'modal-root'

  document.body.prepend(modalRoot)
}

const stopEventPropagation: MouseEventHandler = (event: MouseEvent) => {
  event.stopPropagation()
}

interface ModalProps {
  wrapper?: (c: any) => any
  header?: React.ReactNode
  footer?: React.ReactNode
  onClose?: any
  children: React.ReactNode
  error?: any
  responsive?: boolean
  refocusId?: string
}

export class Modal extends React.Component<ModalProps, {}> {
  modalBodyRef: React.RefObject<any>
  el: Element
  scrollWidth: number
  clickTarget: EventTarget | null

  constructor(props: any) {
    super(props)
    this.modalBodyRef = React.createRef()
    this.el = document.createElement('div')
    this.scrollWidth = window.innerWidth - document.body.clientWidth
    // clickTarget property exists so we can see where a click was initiated in the modal
    this.clickTarget = null
  }

  componentDidMount() {
    modalCount++
    if (modalCount === 1) {
      document.body.classList.add('modal-open')
      document.body.style.margin = `0 ${this.scrollWidth}px 0 0`
    }

    modalRoot?.appendChild(this.el)
  }

  componentWillUnmount() {
    modalCount--
    if (modalCount === 0) {
      document.body.classList.remove('modal-open')
      document.body.style.margin = ''
    }
    modalRoot?.removeChild(this.el)
  }

  handleOutsideClick = (e: MouseEvent, onClose?: (e: MouseEvent) => void) => {
    if (e.target === this.clickTarget) {
      onClose && onClose(e)
    }
  }

  handleInsideClick: MouseEventHandler = (e: MouseEvent) => {
    this.clickTarget = e.target
  }

  render() {
    const {
      wrapper = (x) => x,
      header,
      onClose,
      footer,
      children,
      error,
      responsive = true
    } = this.props
    const contents = wrapper(
      <FloatingOverlay style={{ zIndex: 1000 }} lockScroll>
        <div
          className={cx(styles.background, { [styles.responsive]: responsive })}
          onClick={(e) => this.handleOutsideClick(e, onClose)}
          onMouseDown={this.handleInsideClick}
        >
          <div
            role='dialog'
            className={styles.modal}
            onClick={stopEventPropagation}
          >
            <div className={styles.headerWrapper}>
              <div className={cx(styles.header, 'type-headline-4')}>
                {typeof header === 'string' ? (
                  <Header>{header}</Header>
                ) : (
                  header
                )}
              </div>
              {onClose && (
                <div className={styles.closeButton}>
                  <CloseButton
                    data-testid='modal-close-button'
                    onClick={onClose}
                  />
                  <KeyHandler
                    keyEventName='keydown'
                    keyValue='Escape'
                    onKeyHandle={onClose}
                  />
                </div>
              )}
            </div>
            {error && <ErrorComponent error={error} />}
            <div
              data-testid='modal-body'
              className={styles.body}
              ref={this.modalBodyRef}
            >
              {children}
            </div>
            {footer && <div className={styles.footer}>{footer}</div>}
          </div>
        </div>
      </FloatingOverlay>
    )
    return ReactDOM.createPortal(contents, this.el)
  }
}

interface HeaderParams {
  children: React.ReactNode
}

export const Header = ({ children }: HeaderParams) => (
  <h2
    data-testid='modal-default-header'
    className={cx(styles.defaultHeader, 'type-headline-4')}
  >
    {children}
  </h2>
)
