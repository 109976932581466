import React from 'react'
import { getIn, useFormikContext } from 'formik'
import { isSelected } from '@local/modifier-form-helpers'
import { Badge, Colors } from '@toasttab/buffet-pui-badge'
import styles from './Required.module.css'
import { DoneIcon } from '@toasttab/buffet-pui-icons'

export interface RequiredProps {
  name: string
}

export const Required = ({ name }: RequiredProps) => {
  const { errors, values } = useFormikContext()
  const err = getIn(errors, name)
  let value = getIn(values, name)
  if (Array.isArray(value)) {
    value = value.find(isSelected)
  }

  const satisfied = value && !err

  const getBadgeProps = (isSatisfied: boolean) =>
    isSatisfied
      ? { color: Colors.success, iconLeft: <DoneIcon /> }
      : { color: Colors.neutral }

  return (
    <div data-testid='modifier-required-container' className={styles.required}>
      <Badge {...getBadgeProps(satisfied)}>Required</Badge>
    </div>
  )
}

Required.displayName = 'Required'
