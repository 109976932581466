/* eslint-disable no-unused-vars */
import { v4 as uuidv4 } from 'uuid'
import isNil from 'lodash/isNil'
import { getExperimentsStorage } from './experiments-storage'
import { FF } from '@local/do-secundo-feature-flag'

const createVariantBucket = (numVariants, string) => {
  return hashCode(string) % numVariants
}

/**
 * Accepts buckets weights where the first weight corresponds to the weight of the first variant(0 based count) and returns a variant.
 * Example: Input: 2, 1, 1. Variant 0 - 50%, variant 1 - 25%, variant 2 - 25%.
 * @param  {...number} weights
 * @returns {number} Variant
 */

const createWeightedBucket = (...weights) => {
  const buckets = weights.reduce(
    (acc, val, idx) => [...acc, ...Array.from({ length: val }, () => idx)],
    []
  )

  const randomIndex = Math.floor(Math.random() * buckets.length)

  return buckets[randomIndex]
}

/**********************************************
Experiment Name: Price Transparency.
Description: Users will see Toast price or Toast price with competitor ordering service's price crossed out
Variants:
  0: Control - No Treatment - 50.0%
  1: Treatment
**********************************************/
export const XP_PRICE_TRANSPARENCY = {
  experimentName: 'xp-price-transparency',
  flagName: FF.PRICE_TRANSPARENCY,
  useInitVariant() {
    const ctx = XP_PRICE_TRANSPARENCY
    const experimentStorage = getExperimentsStorage()
    const storedVariant = experimentStorage.get([ctx.experimentName])
    // allows to manually override variant for testing purposes
    if (!isNil(storedVariant)) {
      return storedVariant
    }
    // each variant has a 50.00% chance of applying
    const variant = createWeightedBucket(1, 1)
    experimentStorage.set(ctx.experimentName, variant)
    return variant
  },
  getTreatment(variant) {
    return variant
  }
}

// Basic port of Java's String.hashCode. Not particularly tuned for handling collisions,
// which in this case is not too important
function hashCode(s) {
  for (var i = 0, h = 0; i < s.length; i++) {
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0
  }
  return Math.abs(h)
}
