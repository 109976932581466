import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import { Loading } from '@local/do-secundo-loading'
// NOTE: CartIcon here is filler until CartIcon component (it's not just an SVG) is moved into a package (requires availability provider)
import { CartIcon } from '@toasttab/buffet-pui-icons'

import styles from './CartButton.module.css'

enum ButtonStyles {
  DEFAULT = '',
  ROUNDED = 'rounded',
  FLOATING = 'floating'
}

interface CartButtonParams {
  type: 'submit' | 'reset' | 'button'
  to?: string
  left: any
  right: any
  disabled: boolean
  loading: boolean
  variant: ButtonStyles
  showModificationErrors: boolean
}

export const CartButton = ({
  to,
  left,
  right,
  type,
  disabled = false,
  loading,
  variant = ButtonStyles.DEFAULT,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showModificationErrors,
  ...props
}: CartButtonParams) => {
  const buttonStylesCx = React.useMemo(() => {
    return {
      [styles.rounded]: variant === ButtonStyles.ROUNDED,
      [styles.floating]: variant === ButtonStyles.FLOATING
    }
  }, [variant])
  if (loading) {
    return (
      <button
        className={cx(styles.cartButton, 'type-default', buttonStylesCx)}
        type={type}
        {...props}
        disabled
      >
        <Loading />
      </button>
    )
  }
  const buttonContent = (
    <>
      <span className={cx(styles.cartButtonWrapper, 'type-default')}>
        <span className={styles.cartIcon}>
          <CartIcon />
        </span>
        {left}
      </span>
      <span className={'type-default'}>{right}</span>
    </>
  )
  if (type || disabled) {
    return (
      <button
        className={cx(styles.cartButton, 'type-default', buttonStylesCx)}
        type={type}
        disabled={disabled}
        {...props}
      >
        {buttonContent}
      </button>
    )
  }
  return (
    <Link
      className={cx(styles.cartButton, buttonStylesCx)}
      to={to || ''}
      {...props}
    >
      {buttonContent}
    </Link>
  )
}
