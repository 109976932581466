import React from 'react'
import { Dollars } from '@local/do-secundo-dollars'
import cx from 'classnames'

import styles from './MenuItemPrice.module.css'

interface MenuItemPriceParams {
  price: number
  usesFractionalQuantity?: boolean
  unitOfMeasure?: string
  shouldRenderSchema?: boolean
}

export const MenuItemPrice = ({
  price,
  usesFractionalQuantity = false,
  unitOfMeasure = '',
  shouldRenderSchema
}: MenuItemPriceParams) => (
  <span
    data-testid='menu-item-price'
    className={cx(styles.price, 'type-default')}
    itemProp={shouldRenderSchema ? 'offers' : undefined}
    itemScope={shouldRenderSchema}
    itemType={shouldRenderSchema ? 'https://schema.org/Offer' : undefined}
  >
    <Dollars amount={price} />
    {usesFractionalQuantity && `/${unitOfMeasure.toLowerCase()}`}
  </span>
)
