import * as React from 'react'
import cx from 'classnames'
import styles from './ItemModifierModalError.module.css'
import { ErrorComponent } from '@local/error'

type ResetRetriesFn = () => void

export interface ItemModifierModalErrorProps {
  error: string
  retries: number
  resetRetries: ResetRetriesFn
}

export const ItemModifierModalError = ({
  error,
  retries,
  resetRetries
}: ItemModifierModalErrorProps) => {
  const [shimmy, setShimmy] = React.useState(retries > 0)
  const [moveOut, setMoveOut] = React.useState(false)
  React.useEffect(() => {
    setShimmy(retries > 0)
  }, [retries])
  const errorObj = {
    message: error,
    retryMessage: 'Try again, or contact the restaurant directly to order.'
  }

  return (
    <div
      role={'region'}
      aria-live={'polite'}
      className={cx(styles.errorWrapper, {
        [styles.shimmy]: shimmy,
        [styles.moveIn]: !shimmy,
        [styles.moveOut]: moveOut
      })}
    >
      <ErrorComponent
        error={errorObj}
        isCloseable={true}
        closeAction={() => {
          setMoveOut(true)
          resetRetries()
        }}
      />
    </div>
  )
}
