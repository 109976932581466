import { Model } from './Model'
import { Modifier, ModifierModel } from './Modifier'
import { MenuItemDetails } from '@local/do-secundo-model'

export interface ModifierGroupModel {
  name: string
  modifiers: any[]
  maxSelections: number
  minSelections: number
  pricingMode: string
  guid: string
  isSingleSelect: boolean
}

export class ModifierGroup extends Model implements ModifierGroupModel {
  private parent: Modifier | MenuItemDetails
  name: string
  modifiers: any[]
  maxSelections: number
  minSelections: number
  pricingMode: string
  guid: string
  selectedGuid: string

  constructor(
    modifierGroup: ModifierGroupModel,
    parent: Modifier | MenuItemDetails
  ) {
    super(modifierGroup)
    this.modifiers = this.mapModifiers(modifierGroup.modifiers || [])
    this.parent = parent
    this.selectedGuid = ''
    this.name = modifierGroup.name
    this.maxSelections = modifierGroup.maxSelections
    this.minSelections = modifierGroup.minSelections
    this.pricingMode = modifierGroup.pricingMode
    this.guid = modifierGroup.guid
  }

  mapModifiers(modifiers: ModifierModel[]) {
    return modifiers.map((modifier, i) => new Modifier(modifier, this, i))
  }

  getNextValues() {
    return this.modifiers
  }

  resetDefaults() {}

  /**
   * @returns {boolean} True if the constraints only allow one selection in the group.
   */
  get isSingleSelect() {
    return this.maxSelections === 1 && this.minSelections === 1
  }

  get fieldName() {
    return `modifiers.${this.guid}`
  }

  /**
   * @returns {string} The formik key for this group
   */
  get valueFieldName() {
    return `${this.fieldName}.value`
  }

  /**
   * @returns {Modifier|null} If the parent is a modifier return it else
   *   return null
   */
  get parentModifier() {
    if (this.parent instanceof Modifier) {
      return this.parent
    }

    return null
  }
}
