import React from 'react'
import PropTypes from 'prop-types'

import { Modal } from '@local/do-secundo-modal'

import { Loading } from '@toasttab/do-secundo-loading'

export const LoadingModal = ({ onClose }) => {
  return (
    <Modal onClose={onClose}>
      <div className='md:pt-56 md:pb-56 flex justify-center items-center h-full'>
        <Loading variant='secondary' />
      </div>
    </Modal>
  )
}

LoadingModal.propTypes = {
  onClose: PropTypes.func.isRequired
}
