import * as React from 'react'
import { useModifierFormHelpers } from '@local/do-secundo-use-modifier-form-helpers'
import { Field } from 'formik'
import { ItemModifierModal } from '../ItemModifierModal'
import {
  FractionalItemQuantity,
  LoadingModal,
  ErrorModal
} from '@local/do-secundo-modifiers'
import { ItemQuantity } from '@local/do-secundo-itemquantity'
import { getItemPrice } from '@local/pricingutility'
import { useMenuQueriesContext } from '@local/menu-data-provider'
import { useSubscriptionsValues } from '@local/subscriptions-values-provider'
import { getQueryInput, trackOnSubmit } from './helpers'
import { FF, useFlag } from '@local/do-secundo-feature-flag'

export const ItemModifierModalWrapper = ({ onClose = () => {} }) => {
  const { data, loading, error, submitQuery, isAddItemMode } =
    useMenuQueriesContext()
  const {
    doItemDetailsData,
    itemDetailsData,
    transformedMenuData,
    itemDetails,
    basePrice,
    groupName
  } = data
  const {
    restaurant: { orderingAvailable, specialRequestsConfig }
  } = useSubscriptionsValues()
  const featuredItemsEnabled = useFlag(FF.FEATURED_ITEMS)
  const popularItemsEnabled = useFlag(FF.OPT_POPULAR_ITEMS)

  /*
    MenuItemDetails(add) stores item guid under .guid
    SelectionItemDetails(edit) stores item guid under .itemGuid(and .guid is selectionGuid)
  */
  const itemGuid = itemDetailsData?.itemGuid || itemDetailsData?.guid
  const { itemGroupGuid } = itemDetailsData || {}
  const { usesFractionalQuantity, fractionalQuantity, unitOfMeasure } =
    itemDetails || {}

  const [updateCartErrorMessage, setUpdateCartErrorMessage] = React.useState<
    string | null
  >(null)

  const updateErrorMessage = (errorMessage: string) => {
    setUpdateCartErrorMessage(null) // need this to trigger animation
    setUpdateCartErrorMessage(errorMessage)
  }

  const [retries, setRetries] = React.useState(-1)

  React.useEffect(() => {
    if (updateCartErrorMessage) {
      setRetries((retries) => retries + 1)
    }
  }, [updateCartErrorMessage])

  const { getComputedPrice, getModifierGroups, formikProps, getSelectedNames } =
    useModifierFormHelpers({
      itemDetails,
      itemGroupGuid,
      usesFractionalQuantity,
      fractionalQuantity
    })

  const handleSubmit = React.useCallback(
    (
      values: any,
      { setSubmitting }: { setSubmitting: (value: boolean) => void }
    ) => {
      const itemQueryInput = getQueryInput(values, getModifierGroups)

      try {
        submitQuery({
          itemGuid,
          itemGroupGuid,
          itemMasterId: itemDetails.masterId,
          ...itemQueryInput
        }).subscribe((response: any) => {
          if (response.kind === 'ERROR') {
            setSubmitting(false)
            updateErrorMessage(response.message)
          } else {
            setUpdateCartErrorMessage(null)
            setSubmitting(false)
            trackOnSubmit(
              featuredItemsEnabled,
              popularItemsEnabled,
              doItemDetailsData,
              groupName
            )
            onClose()
          }
        })
      } catch (error) {
        updateErrorMessage('Unable to add item to cart.')
      }
    },
    [
      getModifierGroups,
      itemDetails,
      doItemDetailsData,
      onClose,
      itemGuid,
      itemGroupGuid,
      submitQuery,
      featuredItemsEnabled,
      popularItemsEnabled
    ]
  )

  const getDisplayPrice = React.useCallback(
    (values: any) => {
      if (!usesFractionalQuantity) {
        if (
          !updateCartErrorMessage &&
          !error &&
          !loading &&
          doItemDetailsData
        ) {
          const { menuItem } = doItemDetailsData

          const itemModifierGroups = getModifierGroups(
            values,
            values.quantity,
            false
          )
          const itemPrice = getItemPrice({
            menuItem,
            itemModifierGroups,
            quantity: values.quantity,
            transformedMenuData
          })
          return itemPrice
        }
      }

      if (usesFractionalQuantity) {
        const fractionalBasePrice = basePrice * values.fractionalQuantity
        const modifierPrice = getComputedPrice(values, values.quantity)

        return fractionalBasePrice + modifierPrice
      }

      return (
        (getComputedPrice(values, values.quantity) + basePrice) *
        values.quantity
      )
    },
    [
      getComputedPrice,
      basePrice,
      usesFractionalQuantity,
      doItemDetailsData,
      getModifierGroups,
      transformedMenuData,
      error,
      updateCartErrorMessage,
      loading
    ]
  )

  if (loading) {
    return <LoadingModal onClose={onClose} />
  }
  if (error) {
    return <ErrorModal onClose={onClose} error={error} />
  }

  if (!itemDetails) {
    return <LoadingModal onClose={onClose} />
  }
  const fractionalQuantityInput = usesFractionalQuantity ? (
    <Field
      name='fractionalQuantity'
      component={FractionalItemQuantity}
      unitOfMeasure={unitOfMeasure}
      price={basePrice}
    />
  ) : null

  const quantityInput = usesFractionalQuantity ? null : (
    <Field name='quantity' component={ItemQuantity} />
  )
  return (
    <ItemModifierModal
      {...{
        formikProps,
        handleSubmit,
        getModifierGroups,
        doItemDetailsData,
        transformedMenuData,
        itemDetails,
        getDisplayPrice,
        quantityInput,
        getSelectedNames,
        fractionalQuantityInput,
        onClose,
        orderingAvailable,
        specialRequestsConfig,
        isEdit: !isAddItemMode,
        error: updateCartErrorMessage,
        retries,
        resetRetries: () => setRetries(-1)
      }}
    />
  )
}
