import * as React from 'react'
import {
  CheckboxUnselectedIcon,
  CheckboxSelectedIcon
} from '@toasttab/buffet-pui-icons'

export interface CheckboxIconProps {
  icon?: React.ComponentType
  value: boolean | number
}

export const CheckboxIcon = ({ icon: Icon, value }: CheckboxIconProps) => {
  if (value) return <CheckboxSelectedIcon />
  if (Icon) return <Icon />
  return <CheckboxUnselectedIcon />
}
