import React from 'react'
import { Button, ButtonVariant } from '@local/do-secundo-button'
import { FF, useAtLeastOneFlag } from '@local/do-secundo-feature-flag'
import { ItemTag } from '@toasttab/do-secundo-item-tag'
import { Shelf } from 'cornucopia-apis'
import { useMenuQueriesContext } from '@local/menu-data-provider'
import styles from './MenuItem.module.css'
import { Badge, Colors } from '@toasttab/buffet-pui-badge'
import { useIsIntlRestaurant } from '../../../../hooks/use-is-intl-restaurant'

interface MenuItemParams {
  nestedModifierName?: string
  description?: string
  calories?: number
  imageUrl?: string
  containsAlcohol?: boolean
  onRemoveModifier: () => void
  dietaryItemTags: { name: string }[]
}

export const MenuItem = ({
  nestedModifierName,
  description,
  calories,
  imageUrl,
  onRemoveModifier,
  containsAlcohol,
  dietaryItemTags
}: MenuItemParams) => {
  const itemTagsEnabled = useAtLeastOneFlag([FF.OO_ITEM_TAGS, FF.OPT_ITEM_TAGS])
  const { data } = useMenuQueriesContext()
  const isIntl = useIsIntlRestaurant()
  const itemGuid =
    data?.itemDetailsData?.itemGuid || data?.itemDetailsData?.guid

  return (
    <div className={styles.menuItem}>
      {nestedModifierName && (
        <h3 className={styles.nestedName}>{nestedModifierName}</h3>
      )}
      <p data-testid='modifier-item-description' className={styles.description}>
        {description}
      </p>
      {calories && calories > 0 && (
        <p data-testid='modifier-item-calories' className={styles.calories}>
          {calories} Calories
        </p>
      )}
      {itemTagsEnabled && (
        <div className='flex flex-wrap gap-2 mt-6'>
          {dietaryItemTags.map(({ name }) => (
            <ItemTag key={name} tag={name} />
          ))}
        </div>
      )}
      <Shelf
        name='corn-menu-item-detail-banners'
        params={{
          itemGuid,
          experiments: { 'xp-menu-badges': 1 }
        }}
      />
      {imageUrl && (
        <div
          data-testid='modifier-image-url'
          className={styles.image}
          style={{ backgroundImage: `url(${imageUrl})` }}
        />
      )}
      {containsAlcohol && (
        <div
          className='flex items-center'
          data-testid='modifier-id-required-badge'
        >
          <Badge color={Colors.gray}>
            <p className='text-sm'>ID REQUIRED</p>
          </Badge>
          {isIntl ? (
            <div className='ml-2' data-testid='proof-of-age-text'>
              Proof of age requried
            </div>
          ) : (
            <div className='ml-2' data-testid='age-requirement-text'>
              Must be 21+ to purchase alcohol
            </div>
          )}
        </div>
      )}
      {onRemoveModifier && (
        <Button
          data-testid='remove-nested-modifier'
          variant={ButtonVariant.PRIMARY}
          responsive
          onClick={onRemoveModifier}
        >
          Remove {nestedModifierName}
        </Button>
      )}
    </div>
  )
}
