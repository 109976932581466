import * as Yup from 'yup'

export const inputTypes = {}

export const isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n)

export const getQuantity = ({ quantity, selected }) =>
  isNumeric(quantity) ? quantity : Number(selected || 0)

export const isSelected = (modifier) =>
  Boolean(Math.max(getQuantity(modifier), 0))

inputTypes.checkbox = {
  APIToFormValue: (modifiers) => {
    return modifiers.map((o) => ({
      itemGuid: o.itemGuid,
      selected: isSelected(o)
    }))
  },
  getSchema: ({ minSelections, maxSelections }) => {
    let valueSchema = Yup.array()
      .of(
        Yup.object().shape({
          selected: Yup.bool()
        })
      )
      .compact((a) => !isSelected(a))
      .min(minSelections, 'min: ${min}') // eslint-disable-line no-template-curly-in-string

    if (maxSelections) {
      valueSchema = valueSchema.max(maxSelections, 'max: ${max}') // eslint-disable-line no-template-curly-in-string
    }

    const schema = Yup.object().shape({
      value: valueSchema
    })

    return schema
  },
  formValueToApi: (value, itemGroupGuid, quantity) =>
    value.reduce(
      (acc, { itemGuid, selected }) =>
        !selected
          ? acc
          : [
              ...acc,
              {
                itemGuid,
                itemGroupGuid,
                quantity
              }
            ],
      []
    )
}

/**
 * Input type that supports modifier quantities
 */
inputTypes.modifierBoxInput = {
  APIToFormValue: (modifiers) => {
    return modifiers.map((o) => {
      return {
        itemGuid: o.itemGuid,
        quantity: getQuantity(o)
      }
    })
  },
  getSchema: (modifierGroup) => {
    const { minSelections, maxSelections } = modifierGroup
    const valueSchema = Yup.array()
      .of(
        Yup.object().shape({
          quantity: Yup.number()
        })
      )
      .test(
        'minMaxSelected',
        'missing min/max required selections',
        (values) => {
          // need to do this bc of the duplicate mods so that we can programatically add up quantity to see if they meet our min/max selections allowed
          const totalSelections = values.reduce(
            (acc, modifier) => acc + getQuantity(modifier),
            0
          )
          if (maxSelections && minSelections) {
            return (
              totalSelections >= minSelections &&
              totalSelections <= maxSelections
            )
          } else if (minSelections && !maxSelections) {
            return totalSelections >= minSelections
          } else if (maxSelections && !minSelections) {
            return totalSelections <= maxSelections
          }
          return true
        }
      )

    const schema = Yup.object().shape({
      value: valueSchema
    })

    return schema
  },
  formValueToApi: (value, itemGroupGuid, quantity) => {
    return value.reduce((acc, { itemGuid, quantity: modifierQuantity }) => {
      const newItem = {
        itemGuid,
        itemGroupGuid,
        quantity
      }
      const hasDuplicateModifiers = modifierQuantity > 0

      if (hasDuplicateModifiers) {
        const valueForApi = Array.from(
          { length: modifierQuantity },
          (x) => newItem
        )
        return [...acc, ...valueForApi]
      }

      return acc
    }, [])
  }
}

inputTypes.radio = {
  APIToFormValue: (modifiers) => {
    const found = modifiers.find(isSelected)
    return found ? found.itemGuid : ''
  },
  getSchema: () =>
    Yup.object().shape({ value: Yup.string().required('required') }),
  formValueToApi: (itemGuid, itemGroupGuid, quantity) => [
    {
      itemGuid,
      itemGroupGuid,
      quantity
    }
  ]
}

inputTypes.fractionalQuantity = {
  defaultInitialValue: '1.00',
  getSchema: () =>
    Yup.object().shape({
      fractionalQuantity: Yup.number()
        .moreThan(0.24, 'Minimum is 0.25')
        .required('Required')
    })
}

const isSingleSelect = (min, max) => min === 1 && max === 1

export const getNumSelectedModifiers = (modifierGroup) =>
  modifierGroup.reduce((acc, mod) => acc + getQuantity(mod), 0)

export const getNumSelectedModifiersByGroupGuid = (form, groupGuid) =>
  getNumSelectedModifiers(form.values.modifiers[groupGuid].value)

export const maxCharacters = 200

export const modifierFormHelpers = ({
  modifierGroups,
  itemGroupGuid,
  quantity = 1,
  usesFractionalQuantity = false,
  fractionalQuantity = {},
  specialInstructions = ''
}) => {
  const typeByGuid = {}
  const getType = (guid) => typeByGuid[guid]
  const initTypeByGuid = ({ guid, minSelections, maxSelections }) => {
    if (isSingleSelect(minSelections, maxSelections)) {
      typeByGuid[guid] = 'radio'
    } else {
      typeByGuid[guid] = 'modifierBoxInput'
    }
  }

  const getInitialModifiers = (modifierGroups) => {
    /** While also composing initialValues,
     * recursively loop through modifierGroups
     * to initModifierGroupByGuid */
    return modifierGroups.reduce((acc, group) => {
      initTypeByGuid(group)

      const { guid: groupGuid, modifiers } = group
      const { APIToFormValue } = inputTypes[getType(groupGuid)]

      const detailsByItemGuid = modifiers.reduce((ac, mod) => {
        const { itemGuid, modifierGroups = [], price, name } = mod
        return {
          ...ac,
          [itemGuid]: {
            price,
            name,
            modifiers: getInitialModifiers(modifierGroups)
          }
        }
      }, {})

      return {
        ...acc,
        [groupGuid]: {
          detailsByItemGuid,
          value: APIToFormValue(modifiers)
        }
      }
    }, {})
  }

  // get initial values (deep)
  const initialValues = {
    modifiers: getInitialModifiers(modifierGroups),
    quantity,
    specialInstructions
  }

  // get schema shape (shallow)
  const schemaShape = modifierGroups.reduce((acc, group) => {
    const { guid: groupGuid } = group
    const { getSchema } = inputTypes[getType(groupGuid)]

    return { ...acc, [groupGuid]: getSchema(group) }
  }, {})

  // add special instructions to schema shape
  let schema = Yup.object().shape({
    modifiers: Yup.object().shape(schemaShape),
    specialInstructions: Yup.string().max(
      maxCharacters,
      // eslint-disable-next-line no-template-curly-in-string
      'max ${max} characters'
    )
  })

  // account for fractional quantity
  if (usesFractionalQuantity) {
    initialValues.fractionalQuantity =
      fractionalQuantity.quantity ||
      inputTypes.fractionalQuantity.defaultInitialValue
    schema = schema.concat(inputTypes.fractionalQuantity.getSchema())
  }

  // calls corresponding formValueToApi function for radio/checkbox recursively
  const getModifierGroups = (
    values,
    quantity = 1,
    convertFormValueForApi = true
  ) =>
    Object.entries(values.modifiers).map(
      ([guid, { value, detailsByItemGuid }]) => {
        const type = getType(guid)
        const { formValueToApi } = inputTypes[getType(guid)]
        const shouldReturnFormValue =
          !convertFormValueForApi && type === 'modifierBoxInput'
        const valueWhenShouldReturnFormValue = Array.isArray(value) ? value : []
        let getModifiersParameter

        if (shouldReturnFormValue) {
          getModifiersParameter = valueWhenShouldReturnFormValue
        } else {
          // this updated qty is used for PMM aka MMP (Menu Modifier Pricing)
          // quantity for the modifier is updated when the item quantity is increased for non-modifier-quantity enabled modifiers
          // for MMP we dont want this, bc modifierGroup price should only return the price of one modifier group
          // getItemPrice is responsible for calculating total price of item, its modifier groups and quantity
          const qty = !convertFormValueForApi ? 1 : quantity
          getModifiersParameter = formValueToApi(value, itemGroupGuid, qty)
        }

        const getModifiers = (vals) => {
          return vals.map(({ itemGuid, ...rest }) => {
            const nestedValues = detailsByItemGuid[itemGuid]
            return {
              itemGuid,
              ...rest,
              // nestedValues will be undefined if a radio group's value is not selected (i.e. itemGuid === '')
              modifierGroups: nestedValues
                ? getModifierGroups(
                    nestedValues,
                    quantity,
                    convertFormValueForApi
                  )
                : []
            }
          })
        }

        const modifiers = getModifiers(getModifiersParameter)

        return { guid, modifiers }
      }
    )

  // get calculated price from formik values recursively
  const getComputedPrice = (values) => {
    let res = 0
    getModifierGroups(values).forEach(({ guid: groupGuid, modifiers }) => {
      modifiers.forEach(({ itemGuid }) => {
        // itemGuid can be an empty string when radio is unselected
        if (itemGuid) {
          const details =
            values.modifiers[groupGuid].detailsByItemGuid[itemGuid]
          res += details.price + getComputedPrice(details)
        }
      })
    })
    return res
  }

  // get an array of names of selected guids from formik values shallowly
  const getSelectedNames = (values) => {
    const res = []
    getModifierGroups(values).forEach(({ guid: groupGuid, modifiers }) => {
      modifiers.forEach(({ itemGuid }) => {
        // itemGuid can be an empty string when radio is unselected
        if (itemGuid) {
          const details =
            values.modifiers[groupGuid].detailsByItemGuid[itemGuid]
          res.push(details.name)
        }
      })
    })
    return res
  }

  return {
    initialValues,
    schema,
    getModifierGroups,
    getComputedPrice,
    maxCharacters,
    getSelectedNames
  }
}

const MENU_ITEM_DETAILS = 'menuItemDetails'
const SELECTION_ITEM_DETAILS = 'selectionItemDetails'

const getDetails = (data, key) => {
  if (!data?.[key]) return null
  const { [key]: itemDetails } = data
  const { fractionalQuantity = {} } = itemDetails

  const unitOfMeasure =
    itemDetails.unitOfMeasure || fractionalQuantity.unitOfMeasure || ''

  return {
    ...itemDetails,
    unitOfMeasure,
    fractionalQuantity
  }
}

const getItemDetails = (data) => getDetails(data, MENU_ITEM_DETAILS)

const getSelectionDetails = (data) => getDetails(data, SELECTION_ITEM_DETAILS)

export {
  getItemDetails,
  getSelectionDetails,
  MENU_ITEM_DETAILS,
  SELECTION_ITEM_DETAILS,
  getDetails
}
