import React from 'react'
import { FieldArray, Field, getIn, useFormikContext } from 'formik'

import { Radio } from '@local/do-secundo-form'
import { ModifierFieldset } from '@local/do-secundo-modifierfieldset'
import { ModifierFieldLabel } from '@local/do-secundo-modifier-field-label'
import { useNestedModifiers } from '../NestedModifiersProvider/NestedModifiersProvider'
import { ModifierGroupModel } from '@local/do-secundo-model'
import { ModifierBoxInput } from '@local/do-secundo-modifier-box-input'
import {
  getNumSelectedModifiers,
  isSelected
} from '@local/modifier-form-helpers'
import {
  getPriceToRender,
  getModifierQuantityFromFormik
} from '@local/do-secundo-modifier-price-helpers'
import styles from './ModifierGroup.module.css'
import { toNumber } from 'lodash'

interface ModifierGroupComponentParams {
  modifierGroup: ModifierGroupModel
  getSelectedNames: (values: any) => string[]
  modifierGroupPrice: number
  isSequencePricing: boolean
  minPrice: number
  defaultOptionsChargePrice?: boolean
  selectedSizeBasedPrice: number
}
export const ModifierGroup = ({
  modifierGroup,
  getSelectedNames,
  modifierGroupPrice,
  isSequencePricing,
  minPrice,
  defaultOptionsChargePrice,
  selectedSizeBasedPrice
}: ModifierGroupComponentParams) => {
  const { pushNestedModifier, recordCurrentSelections } =
    useNestedModifiers() as any
  const { values, setFieldValue, initialValues } = useFormikContext<any>()

  const {
    name,
    modifiers,
    maxSelections,
    minSelections,
    pricingMode,
    guid: groupGuid,
    isSingleSelect
  } = modifierGroup

  const getModifierFieldComponent = (modifier: any) => {
    /**
     * Handler triggered when a nested modifier is clicked
     * Responsible for setting the value of that modifier and then updating its children modifiers after those have been updated
     */
    const onSelect = () => {
      if (!modifier.isSelected) {
        const shouldSetQuantity = !isSingleSelect
        const existingValue = shouldSetQuantity
          ? getIn(initialValues, modifier.valueFieldNameQuantity)
          : getIn(initialValues, modifier.valueFieldName)
        const currentValue = shouldSetQuantity
          ? getIn(values, modifier.valueFieldNameQuantity)
          : getIn(values, modifier.valueFieldName)
        const isSet =
          typeof currentValue === 'boolean' ||
          (typeof currentValue === 'string' && currentValue.length > 0) ||
          (typeof currentValue === 'number' && currentValue > 0)
        if (shouldSetQuantity) {
          const numericValue = toNumber(currentValue)
          modifier.setModifierQuantity(
            setFieldValue,
            isSet ? numericValue + 1 : existingValue
          )
        } else {
          modifier.setSelected(
            setFieldValue,
            isSet ? currentValue : existingValue
          )
        }
      }

      const parentModifier = modifier.parent.parent

      parentModifier.formikValues = values
      recordCurrentSelections(modifier.parent.parent)

      pushNestedModifier(modifier)
    }

    let SelectorComponent: any
    let displayName: string

    if (isSingleSelect) {
      SelectorComponent = Radio
      displayName = 'Radio'
    } else {
      SelectorComponent = ModifierBoxInput
      displayName = 'ModifierBoxInput'
    }

    const Component = (props: any) => {
      const componentProps = {
        onSelect: modifier.hasNested ? onSelect : undefined,
        ...props,
        // !modifier.hasNested below disables duplicate mods on nested modifiers. In the future, we will most likely create functionality to enable customization
        ...(displayName === 'Radio'
          ? {}
          : {
              isMultibox: modifier.allowsDuplicates && !modifier.hasNested,
              maxSelections,
              groupGuid
            })
      }

      return <SelectorComponent {...componentProps} />
    }

    if (modifier.hasNested) {
      Component.displayName = `NestedModifier${displayName}Field`
    }

    return Component
  }

  const modifierIsSelected = (
    formValue: string,
    itemGuid: string,
    index: number
  ) => {
    if (isSingleSelect) {
      return formValue === itemGuid
    }
    return isSelected(formValue[index])
  }

  const getMaxSelections = (isSelected: boolean, value: string) =>
    !isSingleSelect &&
    !isSelected &&
    getNumSelectedModifiers(value) === maxSelections
  return (
    modifiers.length > 0 && (
      <ModifierFieldset
        label={name}
        rules={{ minSelections, maxSelections, pricingMode }}
        name={`modifiers.${groupGuid}.value`}
        isSingleSelect={isSingleSelect}
        modifierGroupPrice={modifierGroupPrice}
        isSequencePricing={isSequencePricing}
      >
        <FieldArray
          name={groupGuid}
          render={() => (
            <div data-testid='modifier-group-body' className={styles.modifiers}>
              {modifiers.map((modifier, index) => {
                const {
                  itemGuid,
                  price,
                  name,
                  hasNested,
                  valueFieldName,
                  valueFieldNameQuantity,
                  deepFieldNameByGuid,
                  isDefault
                } = modifier

                const quantity = getModifierQuantityFromFormik(
                  values.modifiers[groupGuid].value,
                  itemGuid
                )

                const fieldName = !isSingleSelect
                  ? valueFieldNameQuantity
                  : valueFieldName
                const formValue = values.modifiers[groupGuid].value
                const isSelected = modifierIsSelected(
                  formValue,
                  itemGuid,
                  index
                )
                const hasMaxSelections = getMaxSelections(isSelected, formValue)
                const isOutOfStock = modifier.outOfStock === true
                const isDisabled = hasMaxSelections || isOutOfStock
                const nestedModifiersValues =
                  values.modifiers[groupGuid].detailsByItemGuid[itemGuid]
                const nestedModifiersNames =
                  hasNested && isSelected
                    ? getSelectedNames(nestedModifiersValues)
                    : []

                // OO-3648: change when OO supports substitution pricing strategy
                const priceProp = getPriceToRender({
                  isDefault,
                  selectedSizeBasedPrice,
                  minPrice,
                  price,
                  defaultOptionsChargePrice,
                  quantity
                })

                return (
                  <div role='group' aria-label={`${name}`} key={itemGuid}>
                    <Field
                      disabled={isDisabled}
                      name={fieldName}
                      value={itemGuid}
                      component={getModifierFieldComponent(modifier)}
                      data-testid={itemGuid}
                      id={deepFieldNameByGuid}
                      label={
                        <ModifierFieldLabel
                          name={name}
                          price={priceProp === null ? undefined : priceProp}
                          hasNested={hasNested}
                          outOfStock={isOutOfStock}
                          nestedModifiersNames={nestedModifiersNames}
                        />
                      }
                    />
                  </div>
                )
              })}
            </div>
          )}
        />
      </ModifierFieldset>
    )
  )
}
