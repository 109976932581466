import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'

import { maxCharacters } from '@local/modifier-form-helpers'
import { MenuItemDetailsShape, ModifierShape } from '@local/do-secundo-model'
import { getItemSize } from '@local/pricingutility'
import { ModifierGroupPricingContainer } from '@local/do-secundo-modifier-group-pricing-container'
import { useItem } from '@local/do-secundo-item-provider'

import { MenuItem, ModifierGroup, SpecialInstructions } from '../../src'
import { getItemContainsAlcohol } from '../modifier-helpers'

export const ModifierBodyPricing = ({
  doMenuItemDetails: { menuItem },
  getModifierGroups,
  transformedMenuData,
  itemDetails,
  isNested = false,
  specialRequestsConfig = { enabled: false },
  deleteButton = null,
  quantityInputField = null,
  onRemoveModifier,
  getSelectedNames = () => []
}) => {
  const { values } = useFormikContext()
  const { itemSize: size, setItemSize } = useItem()

  const itemModifierGroups = getModifierGroups(values, values.quantity, false)
  const itemSize = getItemSize({
    menuItem,
    itemModifierGroups,
    modifierGroupOptionsMap: transformedMenuData.modifierGroupOptionsMap
  })
  const { dietaryItemTags } = menuItem
  // update context only when size selection changed(current itemSize is different from size from the context)
  useEffect(() => {
    if (itemSize && itemSize.guid !== size?.guid) {
      setItemSize(itemSize)
    }
  }, [itemSize, setItemSize, size])

  return (
    <>
      <MenuItem
        nestedModifierName={isNested ? itemDetails.name : undefined}
        calories={itemDetails.calories}
        description={itemDetails.description}
        imageUrl={itemDetails.imageUrl}
        onRemoveModifier={onRemoveModifier}
        dietaryItemTags={dietaryItemTags}
        containsAlcohol={getItemContainsAlcohol(menuItem)}
      />
      {itemDetails.modifierGroups.map((modifierGroup) => (
        <ModifierGroupPricingContainer
          component={ModifierGroup}
          modifierGroup={modifierGroup}
          itemModifierGroups={itemModifierGroups}
          itemSize={size}
          key={modifierGroup.guid}
          transformedMenuData={transformedMenuData}
          getSelectedNames={getSelectedNames}
        />
      ))}
      {specialRequestsConfig.enabled && (
        <SpecialInstructions
          label='Special Instructions'
          name='specialInstructions'
          maxLength={maxCharacters}
          rows={4}
          placeholder={specialRequestsConfig.placeholderMessage}
        />
      )}
      {quantityInputField}
      {deleteButton}
    </>
  )
}

const specialRequestsConfigShape = PropTypes.shape({
  enabled: PropTypes.bool.isRequired,
  placeholderMessage: PropTypes.string
})

const itemDetailsShape = PropTypes.oneOfType([
  MenuItemDetailsShape,
  ModifierShape
])

ModifierBodyPricing.propTypes = {
  itemDetails: itemDetailsShape,
  deleteButton: PropTypes.node,
  specialRequestsConfig: specialRequestsConfigShape,
  quantityInputField: PropTypes.node,
  getSelectedNames: PropTypes.func.isRequired,
  isNested: PropTypes.bool,
  onRemoveModifier: PropTypes.func,
  onConfirmModifier: PropTypes.func
}
