import * as React from 'react'
import { Currency, formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import { checkLocale } from '@toasttab/buffet-pui-locale-utilities'
import { useRestaurant } from '@local/subscriptions-values-provider'

export interface FormatCurrencyProps {
  testId?: string | number
  amount?: number
  showLeadingSign?: boolean
  renderWhenZero?: string | null
}

export const FormatCurrency = ({
  testId = `FormatCurrency`,
  amount = 0,
  showLeadingSign = false,
  renderWhenZero
}: FormatCurrencyProps) => {
  const data = useRestaurant()

  if (amount === 0 && renderWhenZero !== undefined) {
    return <span>{renderWhenZero}</span>
  }

  const leadingSign = showLeadingSign && amount >= 0 ? '+' : ''

  let rxI18n = data?.i18n || {
    currency: Currency['USD'],
    locale: 'en-US',
    country: 'US'
  }
  const currency = rxI18n.currency
  const locale = checkLocale(rxI18n.locale)
  const formattedCurrency = formatCurrency(
    {
      amount,
      currency
    },
    locale,
    {
      trailingZeros: true
    }
  )

  return (
    <div data-testid={testId}>
      {leadingSign}
      {formattedCurrency}
    </div>
  )
}
