import React from 'react'
import PropTypes from 'prop-types'

import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import { CartButton } from '@local/do-secundo-cart-button'

import { useNestedModifiers } from '../NestedModifiersProvider/NestedModifiersProvider'

import { MenuItemDetailsShape, ModifierShape } from '@local/do-secundo-model'

import styles from './ModifiersFooter.module.css'
import { useFormikContext } from 'formik'
import { PriceTransparencyBanner } from '@local/do-secundo-price-transparency-banner'

import { useVariant } from '../../../../hooks/use-variant'
import { XP_PRICE_TRANSPARENCY } from '../../../../components/ExperimentsProvider/experiment-definitions'
import { PriceTransparencyCartButton } from '@local/do-secundo-price-transparency-cart-button'
import { FormatCurrency } from '@local/format-currency'
import { track } from '@toasttab/do-secundo-analytics'

export const ModifiersFooter = ({
  getDisplayPrice,
  onClose,
  itemDetails,
  additionalText = '',
  isEditModal = false,
  quantityInputField = null,
  validationSchema,
  orderingAvailable
}) => {
  const {
    nestedModifier,
    nestedModifierIsValid,
    confirmNestedModifierSelections,
    modalLoading
  } = useNestedModifiers()
  // default to an object in case component is called outside of the formik context
  // allows us to hit a safety check on line 68
  const { values, isSubmitting } = useFormikContext() || {}

  const priceTransparencyVariant = useVariant(
    XP_PRICE_TRANSPARENCY.experimentName
  )

  if (nestedModifier) {
    return (
      <Button
        type={ButtonType.BUTTON}
        variant={ButtonVariant.PRIMARY}
        disabled={!nestedModifierIsValid}
        onClick={confirmNestedModifierSelections}
        data-testid='nested-modifier-add-button'
      >
        Continue
      </Button>
    )
  }

  if (!orderingAvailable) {
    return (
      <Button
        data-testid='close-modifiers-button'
        variant={ButtonVariant.PRIMARY}
        onClick={onClose}
      >
        Close
      </Button>
    )
  }

  if (!values) return null
  const isLoading = isSubmitting || modalLoading || !itemDetails

  let buttonText = isEditModal ? 'Update Cart' : 'Add to Cart'
  buttonText += additionalText

  return (
    <div className={styles.container}>
      {priceTransparencyVariant === 1 && (
        <PriceTransparencyBanner price={getDisplayPrice(values)} />
      )}
      <div className={styles.wrapper}>
        {quantityInputField}
        <div className={styles.buttonWrapper}>
          {priceTransparencyVariant === 1 ? (
            <PriceTransparencyCartButton
              data-testid='add-to-cart-button'
              loading={isLoading}
              disabled={!validationSchema.isValidSync(values)}
              aria-label='Add to cart'
            />
          ) : (
            <CartButton
              variant='rounded'
              data-testid='add-to-cart-button'
              type='submit'
              loading={isLoading}
              disabled={!validationSchema.isValidSync(values)}
              left={<span>{buttonText}</span>}
              right={<FormatCurrency amount={getDisplayPrice(values)} />}
              aria-label='Add to cart'
              onClick={() => {
                track('Attempt Add Item to Cart')
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const itemDetailsShape = PropTypes.oneOfType([
  MenuItemDetailsShape,
  ModifierShape
])

ModifiersFooter.propTypes = {
  getDisplayPrice: PropTypes.func,
  onClose: PropTypes.func,
  itemDetails: itemDetailsShape,
  additionalText: PropTypes.string
}
