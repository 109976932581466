import React, { useCallback } from 'react'
import { useNestedModifiers } from '../NestedModifiersProvider/NestedModifiersProvider'

import { MenuItemDetailsModel, ModifierModel } from '@local/do-secundo-model'

import { Header } from '@local/do-secundo-modal'
import { CloseButton } from '@local/do-secundo-closebutton'
import { ChevronLeftIcon } from '@toasttab/buffet-pui-icons'

import styles from './ModifiersHeader.module.css'

interface ItemDetailsParams {
  itemDetails: MenuItemDetailsModel | ModifierModel
}

export const ModifiersHeader = ({ itemDetails }: ItemDetailsParams) => {
  const { nestedModifier, popNestedModifier } = useNestedModifiers()

  const clickHandler = useCallback(() => {
    popNestedModifier()
  }, [popNestedModifier])

  const headerText = <Header>{itemDetails.name}</Header>

  if (nestedModifier) {
    return (
      <div className={styles.headerNavBack}>
        <CloseButton autoFocus onClick={clickHandler}>
          <ChevronLeftIcon size='md' />
        </CloseButton>
        {headerText}
      </div>
    )
  }

  return headerText
}
