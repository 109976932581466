import React from 'react'

import cx from 'classnames'

import { ErrorMessage } from '@local/do-secundo-form'
import { MenuItemPrice } from '@local/do-secundo-menu-item-price'

import { trimToDecimalValue } from '@local/do-secundo-form-utils'

import styles from './FractionalItemQuantity.module.css'

const isTouched = (fields: any, fieldId: string) => fields[fieldId]

interface FormParams {
  errors: any
  touched: any
  setFieldValue: (name: string, value: any, touched?: boolean) => void
  setFieldTouched: (
    name: string,
    touched: boolean,
    shouldValidate: boolean
  ) => void
}

interface FieldParams {
  name: string
  value: string | number
  onChange?: () => void
  onBlur?: () => void
}

interface FractionalItemQuantityParams {
  field: FieldParams
  form: FormParams
  unitOfMeasure: string
  price: number
}

export const FractionalItemQuantity = ({
  field,
  form,
  ...props
}: FractionalItemQuantityParams) => {
  const { value } = field
  const { unitOfMeasure, price } = props

  const handleInputChange = ({ target }: { target: any }) => {
    form.setFieldValue(field.name, trimToDecimalValue(target.value, 2) || '')
    form.setFieldTouched(field.name, true, false)
  }

  const handleFocus = () => {
    if (!isTouched(form.touched, 'fractionalQuantity')) {
      form.setFieldValue(field.name, '', false)
    }
  }

  const updateForm = () => {
    form.setFieldValue(field.name, String(Number(value).toFixed(2)))
  }

  const displayUnitOfMeasure = unitOfMeasure.toLowerCase()

  const error = form.errors[field.name]

  return (
    <div data-testid='fractional-item-quantity' className={styles.wrapper}>
      <div className={styles.descriptionWrapper}>
        <div className={styles.description}>Quantity</div>
        {error && <ErrorMessage name={field.name} />}
      </div>
      <div className={styles.inputWrapper}>
        <input
          className={cx(styles.input, { [styles.error]: error })}
          id='fractional_quantity_input'
          name='FractionalQuantityInput'
          type='number'
          step='any'
          min='0.00'
          placeholder='0.00'
          inputMode='decimal'
          value={value}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={updateForm}
        />
        <label
          id='fractional_quantity_input_label'
          className={styles.inputLabel}
          htmlFor='FractionalQuantityInput'
        >
          {displayUnitOfMeasure}
        </label>
      </div>
      <div className={styles.price}>
        <MenuItemPrice
          usesFractionalQuantity
          price={price}
          unitOfMeasure={unitOfMeasure}
        />
      </div>
    </div>
  )
}
