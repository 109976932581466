import React from 'react'
import cx from 'classnames'

import { onEnterOrSpace } from '@local/do-secundo-key-handler'

import styles from './Radio.module.css'

enum RadioStyles {
  DEFAULT = 'default',
  TAB = 'tab',
  CHUNKY = 'chunky',
  SVG = 'svg'
}

interface FieldParam {
  name: string
  value: string
  onChange: (...args: any[]) => void
  onBlur: () => void
}

export interface RadioParams {
  field: FieldParam
  id: string
  label: any
  value: string
  variant?: RadioStyles
  onSelect: () => void
  form: any
  disabled: boolean
}

export const Radio = ({
  field,
  id,
  value,
  label,
  form,
  disabled,
  variant = RadioStyles.DEFAULT,
  onSelect,
  ...props
}: RadioParams) => {
  const { labelStyles, inputGroupStyles, radioStyles } = React.useMemo(() => {
    return {
      labelStyles: {
        [styles.defaultLabel]: variant === RadioStyles.DEFAULT,
        [styles.chunkyLabel]: variant === RadioStyles.CHUNKY,
        [styles.svgLabel]: variant === RadioStyles.SVG,
        [styles.tabLabel]: variant === RadioStyles.TAB
      },
      inputGroupStyles: {
        [styles.defaultInputGroup]: variant === RadioStyles.DEFAULT,
        [styles.chunkyInputGroup]: variant === RadioStyles.CHUNKY,
        [styles.svgInputGroup]: variant === RadioStyles.SVG,
        [styles.tabInputGroup]: variant === RadioStyles.TAB
      },
      radioStyles: {
        [styles.defaultRadio]: variant === RadioStyles.DEFAULT,
        [styles.chunkyRadio]: variant === RadioStyles.CHUNKY,
        [styles.svgRadio]: variant === RadioStyles.SVG,
        [styles.tabRadio]: variant === RadioStyles.TAB
      }
    }
  }, [variant])
  return (
    <div
      data-testid='radio-input-container'
      className={cx(inputGroupStyles, variant)}
    >
      <input
        data-testid='radio-input-control'
        className={cx(radioStyles)}
        name={field.name}
        id={id}
        type='radio'
        value={value}
        checked={field.value === value}
        onClick={onSelect}
        onChange={(...a) => {
          if (onSelect) {
            onSelect()
            return
          }
          form.setFieldTouched(field.name, true)
          field.onChange(...a)
        }}
        onBlur={field.onBlur}
        disabled={disabled}
        {...props}
      />
      <label
        data-testid='radio-label'
        className={cx(labelStyles)}
        htmlFor={id}
        tabIndex={0}
        onKeyDown={onEnterOrSpace(() => {
          if (!disabled) {
            if (onSelect) {
              onSelect()
              return
            }
            form.setFieldValue(field.name, value)
          }
        })}
      >
        {label}
      </label>
    </div>
  )
}
