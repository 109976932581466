import * as React from 'react'

import { useUpdateCart } from 'cornucopia-apis'

import { useGetItemDetails } from '@local/use-get-item-details'
import { useGetSelectionDetails } from '@local/use-get-selection-details'
import { useGetDoMenusItemDetails } from '@local/use-get-do-menus-item-details'
import { VisibilityEnum } from '@local/menu-queries'
import { useSubscriptionsValues } from '@local/subscriptions-values-provider'

export interface MenuDataProviderProps {
  children: React.ReactNode
  urlParams: {
    itemGuid?: string | null
    itemGroupGuid?: string | null
    groupName?: string | null
  }
  shortUrl: string
  restaurantGuid: string
  menuVisibility: VisibilityEnum
}

interface MenuQueriesContextShape {
  data: any
  loading: boolean
  error: {}
  submitQuery: (input: any) => any
  isAddItemMode: boolean
}

const MenuQueriesContext = React.createContext<MenuQueriesContextShape>(
  {} as MenuQueriesContextShape
)

export const MenuDataProvider = ({
  children,
  urlParams,
  shortUrl,
  restaurantGuid,
  menuVisibility
}: MenuDataProviderProps) => {
  const isAddItemMode = !!urlParams.itemGuid
  const updateCart = useUpdateCart()
  const { fulfillment, editItemUserRequestData } = useSubscriptionsValues()
  // useGetItemDetails and useGetSelectionDetails are mutually exclusive via skip param
  // add item details query
  const {
    loading: menuItemLoading,
    error: menuItemError,
    basePrice: menuItemBasePrice,
    itemDetails: menuItemDetails,
    data: menuItemData
  } = useGetItemDetails({
    itemGuid: urlParams.itemGuid,
    itemGroupGuid: urlParams.itemGroupGuid,
    restaurantGuid,
    shortUrl,
    visibility: menuVisibility,
    dateTime: fulfillment,
    queryOptions: {
      skip: !isAddItemMode
    }
  })

  // We only support OPT or OO carts right now
  const cartApi = menuVisibility === 'KIOSK' ? 'OPT' : 'OO'

  // edit item details query
  const {
    loading: selectionItemLoading,
    error: selectionItemError,
    basePrice: selectionItemBasePrice,
    itemDetails: selectionItemDetails,
    data: selectionItemData
  } = useGetSelectionDetails({
    ...editItemUserRequestData,
    dateTime: fulfillment,
    shortUrl,
    restaurantGuid,
    visibility: menuVisibility,
    queryOptions: {
      skip: isAddItemMode || !editItemUserRequestData.itemGuid
    },
    cartApi
  })

  const itemGuid = urlParams.itemGuid || editItemUserRequestData.itemGuid
  const itemGroupGuid =
    urlParams.itemGroupGuid || editItemUserRequestData.itemGroupGuid

  const {
    loading: doItemDetailsLoading,
    error: doItemDetailsError,
    data: doItemDetailsData,
    transformedMenuData
  } = useGetDoMenusItemDetails({
    itemGuid,
    itemGroupGuid,
    restaurantGuid,
    visibility: menuVisibility,
    dateTime: fulfillment,
    queryOptions: {
      skip: !itemGuid // we dont have itemGuid on the very first render of EditItem modal
    }
  })
  const itemDetails = menuItemDetails || selectionItemDetails
  const basePrice = menuItemBasePrice || selectionItemBasePrice
  const itemDetailsData = menuItemData || selectionItemData

  const submitQuery = React.useCallback(
    (selection: any) => {
      return updateCart(selection, editItemUserRequestData.selectionGuid)
    },
    [updateCart, editItemUserRequestData]
  )

  const data = {
    doItemDetailsData,
    itemDetailsData:
      itemDetailsData?.[
        isAddItemMode ? 'menuItemDetails' : 'selectionItemDetails'
      ],
    transformedMenuData,
    itemDetails,
    basePrice,
    groupName: urlParams.groupName
  }

  const error = doItemDetailsError || selectionItemError || menuItemError

  const loading =
    doItemDetailsLoading || selectionItemLoading || menuItemLoading

  const context = {
    data,
    loading,
    error,
    submitQuery,
    isAddItemMode
  }

  return (
    <MenuQueriesContext.Provider value={context}>
      {children}
    </MenuQueriesContext.Provider>
  )
}

export const useMenuQueriesContext = () => React.useContext(MenuQueriesContext)
