import React from 'react'
import { useLocation } from 'react-router-dom'

export const useTrackPreviousLocation = () => {
  const location = useLocation()
  const [prevLocation, setPrevLocation] = React.useState('/')
  const currLocationRef = React.useRef()

  React.useEffect(() => {
    // Some paths get pushed more than once so we check to make sure
    // we only go back to unique routes.
    const currPath = `${location.pathname}${location.search}`
    if (currLocationRef.current !== currPath) {
      setPrevLocation(currLocationRef.current || '/')
      currLocationRef.current = currPath
    }
  }, [location])
  return prevLocation
}
