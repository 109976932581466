import { useQuery } from '@apollo/client'
import { SELECTION_ITEM_DETAILS } from '../graphql'
import { VisibilityEnum, CartApiType } from '../types'
// this should be autogenerated from the graphql but do this for now
export interface Input {
  restaurantGuid: string
  itemGuid: string
  itemGroupGuid: string
  selectionGuid: string
  shortUrl: string
  cartGuid: string
  dateTime?: string | null
  queryOptions: object
  visibility: VisibilityEnum
  cartApi: CartApiType
  skip?: boolean
}

export const useSelectionDetailsQuery = ({
  restaurantGuid,
  itemGuid,
  itemGroupGuid,
  selectionGuid,
  shortUrl,
  dateTime,
  cartGuid,
  visibility = 'TOAST_ONLINE_ORDERING',
  cartApi,
  queryOptions
}: Input) => {
  const { loading, error, data } = useQuery(SELECTION_ITEM_DETAILS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        cartGuid,
        itemGroupGuid,
        itemGuid,
        restaurantGuid,
        shortUrl,
        dateTime: dateTime || undefined,
        selectionGuid,
        visibility,
        cartApi,
        menuApi: 'DO'
      },
      nestingLevel: 10
    },
    ...queryOptions
  })

  return { loading, error, data }
}
