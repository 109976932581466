import { getGroupPrice, getNestedModifierGroupsPrice } from './helpers'
import {
  PRICING_STRATEGIES,
  checkIfDefaultOptionsChargePrice,
  checkIfSequenceStrategy
} from './pricingUtility'

/**
 * Returns (quantity - 1) when:
 * - modifier is a default modifier
 * - it should not charge a price
 * - its modifierGroup does not have a pricing strategy of 'SEQUENCE_PRICING'
 * - quantity >= 1
 * else return quantity
 * @param {boolean} modifierIsDefault
 * @param {string} defaultOptionsChargePrice - "YES"|"NO" -
 * @param {string}modifierGroupPricingStrategy
 * @param modifierQuantity
 * @returns {number|*}
 */
export function getModifierQuantity({
  modifierIsDefault,
  defaultOptionsChargePrice,
  modifierGroupPricingStrategy,
  modifierQuantity
}) {
  // if modifier is default and modifier group should not charge a price (!defaultOptionsChargePrice),
  // qty should be 1 less to account for the default modifier which should be free
  if (
    modifierIsDefault &&
    !defaultOptionsChargePrice &&
    !checkIfSequenceStrategy(modifierGroupPricingStrategy) &&
    modifierQuantity >= 1
  ) {
    return modifierQuantity - 1
  } else {
    return modifierQuantity
  }
}

/**
 * Iterates over the array of currentOptions aka ModifierOptionReferences to determine which ModifierOptionReference needs to be used to calculate the group price.
 * we determine the current option when the current option is GROUP_PRICE and ModifierGroupInfo.pricingStrategy is SEQUENCE_PRICE|| SIZE_SEQUENCE_PRICE
 * @param {ModifierOptionReference[]} currentOptions
 * @param {ModifierGroupReference} modifierGroupInfo
 * @param {ModifierGroup[]} nestedModifierGroups
 * @returns {{
 *   currentOptionHasBasePrice: (*|boolean),
 *   currentOptionHasMenuSpecificPrice: (*|boolean),
 *   currentOption: ({price}|*),
 *   currentOptionHasNestedModifiers: boolean,
 *   currentOptionHasGroupPrice: ({price}|*|boolean)
 *   }}
 */
export function determineCurrentOptionConditions({
  currentOptions,
  modifierGroupInfo,
  nestedModifierGroups
}) {
  if (!currentOptions) {
    return null
  }

  let currentOption
  try {
    for (let option of currentOptions) {
      if (
        modifierGroupInfo?.modifierOptionReferences.includes(option.referenceId)
      ) {
        currentOption = option
        break
      }
    }
    if (!currentOption) {
      throw new Error(`Could not find one of the modifier referenceIds`)
    }
  } catch (error) {}

  const currentOptionHasBasePrice = Boolean(
    currentOption?.price &&
      currentOption?.pricingStrategy === PRICING_STRATEGIES.BASE_PRICE
  )
  const currentOptionHasMenuSpecificPrice = Boolean(
    currentOption?.price &&
      currentOption?.pricingStrategy === PRICING_STRATEGIES.MENU_SPECIFIC_PRICE
  )
  const currentOptionHasGroupPrice = Boolean(
    currentOption &&
      modifierGroupInfo?.pricingRules &&
      !currentOption.price &&
      currentOption?.pricingStrategy === PRICING_STRATEGIES.GROUP_PRICE
  )
  const currentOptionHasNestedModifiers =
    currentOption && nestedModifierGroups?.length > 0

  return {
    currentOption,
    currentOptionHasBasePrice,
    currentOptionHasMenuSpecificPrice,
    currentOptionHasGroupPrice,
    currentOptionHasNestedModifiers
  }
}

/**
 * Loops over modifier groups and calculates price
 * @param modifierGroup
 * @param {ModifierGroupOptionsMap} modifierGroupOptionsMap
 * @param {ItemSize} itemSize
 * @returns {number}
 */
export function calculateModifierGroupPrice({
  modifierGroup,
  modifierGroupOptionsMap,
  itemSize
}) {
  const { guid: modifierGroupGuid, modifiers } = modifierGroup
  let price = 0

  /* keeps track of:
   * total # of selected modifiers
   * currently selected modifier
   * both used to apply sequence prices
   * should be updated every time once of following conditions is met:
   * - currentOptionHasBasePrice,
   * - currentOptionHasGroupPrice,
   * - currentOptionHasNestedModifiers
   */
  let modifierIndex = 0

  const modifierGroupInfo = modifierGroupOptionsMap?.[modifierGroupGuid]?.[0]
  const defaultOptionsChargePrice = checkIfDefaultOptionsChargePrice(
    modifierGroupInfo?.defaultOptionsChargePrice
  )
  const modifierGroupPricingStrategy = modifierGroupInfo?.pricingStrategy

  for (let modifier of modifiers) {
    const {
      itemGuid: modifierGuid,
      quantity: modifierQuantity,
      modifierGroups: nestedModifierGroups
    } = modifier
    // modifierGuid wont exist if no selections have been made
    if (!modifierGuid) return price

    const currentOptions = modifierGroupOptionsMap[modifierGuid]
    const {
      currentOption,
      currentOptionHasBasePrice,
      currentOptionHasMenuSpecificPrice,
      currentOptionHasGroupPrice,
      currentOptionHasNestedModifiers
    } =
      determineCurrentOptionConditions({
        currentOptions,
        modifierGroupInfo,
        nestedModifierGroups
      }) || {}
    if (!currentOption) {
      continue
    }
    const modifierIsDefault = currentOption?.isDefault
    const updatedModifierQuantity = getModifierQuantity({
      modifierIsDefault,
      defaultOptionsChargePrice,
      modifierGroupPricingStrategy,
      modifierQuantity
    })

    if (currentOptionHasBasePrice || currentOptionHasMenuSpecificPrice) {
      modifierIndex++
      if (!updatedModifierQuantity) {
        continue
      }

      // if this is a size modifier, only add 1 time
      if (itemSize?.guid === currentOption?.guid) {
        price += currentOption?.price
      } else {
        price += currentOption?.price * updatedModifierQuantity
      }
    } else if (currentOptionHasGroupPrice && updatedModifierQuantity) {
      modifierIndex++
      if (!updatedModifierQuantity) {
        continue
      }
      price += getGroupPrice({
        pricingRules: modifierGroupInfo?.pricingRules,
        pricingStrategy: modifierGroupInfo?.pricingStrategy,
        modifierQuantity: updatedModifierQuantity,
        modifierIndex: modifierIndex,
        itemSize
      })
    }

    // a modifier can have nested mods, so we need to:
    // 1. calculate price of modifier plus nested mods if they exist
    // 2. only apply to the price if modifierQuantity > 0 aka parent modifier is selected
    if (currentOptionHasNestedModifiers && modifierQuantity > 0) {
      modifierIndex++
      price += getNestedModifierGroupsPrice({
        modifierGroups: nestedModifierGroups,
        modifierGroupOptionsMap,
        itemSize
      })
    }
  }

  return price
}
