import React from 'react'
import { Link } from 'react-router-dom'

import { CloseIcon } from '@toasttab/buffet-pui-icons'

import styles from './CloseButton.module.css'

type ToStruct =
  | string
  | {
      pathname: string
      search?: string
      hash?: string
      state?: any
    }

interface CloseButtonParams {
  onClick: () => void
  to: ToStruct
  'data-testid'?: string
  children: any
}

interface TagParams {
  onClick: () => void
  to: ToStruct
  dataTestId?: string
  children: any
  className: any
}

const Tag = ({ to, onClick, ...props }: TagParams) =>
  to ? (
    <Link to={to} {...props} />
  ) : (
    <button type='button' aria-label='Close' onClick={onClick} {...props} />
  )

export const CloseButton = ({
  onClick,
  to,
  'data-testid': dataTestId,
  children = (
    <div className='text-oo-black-87'>
      <CloseIcon className='fill-current' />
    </div>
  ),
  ...props
}: CloseButtonParams) => (
  <Tag
    className={styles.closeButton}
    onClick={onClick}
    to={to}
    data-testid={dataTestId}
    {...props}
  >
    {children}
  </Tag>
)
