import React from 'react'

import cx from 'classnames'

import styles from './Loading.module.css'

/* eslint-disable no-unused-vars */
// We use these as strings with the styles module
export const enum LoadingVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LINK = 'link'
}
/* eslint-enable no-unused-vars */

export interface LoadingProps {
  variant?: LoadingVariant
}

export const Loading = ({ variant }: LoadingProps) => {
  const variantStyle = variant && styles[variant]
  return (
    <div className={cx(styles.loading, variantStyle)}>
      <div className={styles.dot} />
      <div className={styles.dot} />
      <div className={styles.dot} />
    </div>
  )
}
