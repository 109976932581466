import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from '../ErrorMessage'

import styles from './Textarea.module.css'

interface TextareaParams {
  disabled?: boolean
  id?: string
  label?: string
  instructions?: React.ReactNode
  placeholder?: string
  errors?: any
  touched?: any
  field: any
  form: any
  rows: number
  maxLength: number
}

export const Textarea = ({
  instructions,
  id,
  field,
  form,
  label,
  placeholder,
  disabled,
  rows,
  maxLength
}: TextareaParams) => {
  const { errors, touched } = form
  const { name } = field
  const textAreaProps = { placeholder, disabled, maxLength, rows }
  const error = errors[name] && touched[name]
  return (
    <>
      <label htmlFor={id}>{label}</label>
      <textarea
        className={styles.textInput}
        {...field}
        id={id}
        {...textAreaProps}
      />
      {error ? (
        <div data-testid='error-message'>
          <ErrorMessage name={name} />
        </div>
      ) : (
        <div data-testid='instructions'>{instructions}</div>
      )}
    </>
  )
}

Textarea.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  instructions: PropTypes.node,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
}
