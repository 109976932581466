/* eslint-disable no-unused-vars */
export enum FF {
  PASSWORDLESS_AUTH_AFTER_CHECKOUT = 'oo-v3-passwordless-auth-after-checkout',
  PASSWORDLESS_AUTH_DURING_CHECKOUT = 'oo-v3-passwordless-auth-during-checkout',
  PASSWORDLESS_AUTH_KILL_SWITCH = 'oo-v3-passwordless-auth-kill-switch',
  GLOBAL_GIFTCARDS = 'oo-global-giftcards-enabled',
  SHOW_CHARGE_INFO = 'oo-v3-show-charge-info',
  DAAS_INTEGRATION = 'oo-daas-integration',
  GIFT_CARD_REDEMPTION = 'oo-v3-gift-card-redemption',
  CHECKOUT_FULFILLMENT = 'oo-v3-checkout-fulfillment',
  NV5_SEO = 'oo-v3-nv5-seo',
  MENU_GROUP_DESCRIPTION = 'oo-v3-menu-group-description',
  BANNER_IMAGE_FIX = 'oo-v3-banner-image-fix',
  BIRDS_EYE_VIEW = 'oo-v3-birds-eye-view',
  USE_SERVICE_AVAILABILITY = 'oo-v3-use-service-availability',
  USE_DO_MENUS = 'oo-v3-use-do-menus',
  ALLOW_APOLLO_RETRY = 'oo-v3-allow-apollo-retry',
  GUEST_FEEDBACK = 'oo-v3-guest-feedback',
  CART_UPSELLS = 'oo-cart-upsell', // non-standard oo-v3 flag, but it's what's used on the back-end to determine if enabled for a restaurant
  MEAL_SELECTOR = 'oo-v3-meal-selector',
  MENU_ITEM_SORT = 'oo-v3-menu-item-sort',
  FLOATING_SEARCH_BUTTON = 'oo-v3-floating-search-button',
  PRICE_TRANSPARENCY = 'oo-price-transparency-ux',
  OO_ITEM_TAGS = 'oo-item-tags',
  OPT_ITEM_TAGS = 'opt-item-tags',
  ALCOHOL_ONBOARDING = 'oo-tds-alcohol',
  FEATURED_ITEMS = 'opt-featured-items',
  OPT_POPULAR_ITEMS = 'opt-popular-items'
}
