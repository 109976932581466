import * as React from 'react'
import ReactDOM from 'react-dom'
import { AppWrapper } from './app/App'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import './index.css'
import { getSentryConfig } from 'do-cornucopia-root'

const tempWindow: any = window
const sentryConfig = getSentryConfig(tempWindow.OO_GLOBALS.mode)

const reactLifecycles = banquetSingleSpaReact({
  portalContainers: ['modal-root'],
  React,
  ReactDOM,
  cssScope: 'do',
  rootComponent: AppWrapper as any,
  singleSpaCssLifecycles,
  sentry: sentryConfig
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
