import * as React from 'react'
import { InfoTooltip } from '@toasttab/buffet-pui-tooltip'
import { useScreenSize, ScreenSize } from '@toasttab/use-screen-size'
import { BottomDrawer } from '@toasttab/do-secundo-bottom-drawer'
import PriceTransparency from '../../../../assets/PriceTransparency.svg'
import cx from 'classnames'
import { InfoIcon } from '@toasttab/buffet-pui-icons'
import { IconButton } from '@toasttab/buffet-pui-buttons'

import styles from './PriceTransparencyBanner.module.css'

export interface PriceTransparencyBannerProps {
  price: number
}

export const PriceTransparencyBanner = ({
  price
}: PriceTransparencyBannerProps) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const screenSize = useScreenSize()
  const isMobileOrTablet = screenSize <= ScreenSize.MD

  const competitorPrice = (1.3 * price).toFixed(2)

  return (
    <>
      <div className={styles.border}>
        <div
          className={cx(
            styles.bannerContainer,
            'flex content-center justify-center items-center gap-1 text-base'
          )}
        >
          <div className={styles.toastPrice}>{`$${price.toFixed(
            2
          )} with Toast`}</div>
          <div className='line-through'>{`Up to $${competitorPrice} with DoorDash`}</div>
          {isMobileOrTablet ? (
            <IconButton
              data-testid='open-bottom-drawer-btn'
              className={styles.infoButton}
              icon={<InfoIcon className={styles.tooltip} />}
              onClick={() => setDrawerOpen(true)}
            />
          ) : (
            <InfoTooltip
              size='sm'
              placement='top-end'
              className={styles.tooltip}
            >
              <div className='text-left text-sm'>
                Restaurants pay less with us, so you pay less with them. Enjoy
                better menu prices and fewer fees.
              </div>
            </InfoTooltip>
          )}
        </div>
      </div>
      <BottomDrawer
        isOpen={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        data-testid='price-transparency-bottom-drawer'
      >
        <div className='flex flex-col items-center text-center px-6'>
          <div className='mb-8'>
            <PriceTransparency />
          </div>
          <div className={cx('mb-2 font-semibold text-xl', styles.title)}>
            Ordering with Toast saves you money
          </div>
          <div className='font-normal text-base mx-6'>
            Restaurants pay less with us, so you pay less with them. Enjoy
            better menu prices and fewer&nbsp;fees.
          </div>
        </div>
      </BottomDrawer>
    </>
  )
}
