import React from 'react'

import { Modal } from '@local/do-secundo-modal'

import { MessageError } from '@toasttab/do-secundo-message-error'

export interface ErrorModalProps {
  onClose(): void
  error?: object
  retry?(): void
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ErrorModal = ({ onClose, error, retry }: ErrorModalProps) => (
  <Modal onClose={onClose}>
    <div className='items-center flex justify-center h-full'>
      <div className='w-96 py-36'>
        <MessageError
          header='Unable to load this item'
          message='Try re-opening the item. If technical issues persist, reach out to the restaurant.'
          testId='item-details-error'
          buttonSecondary={
            retry ? { label: 'Refresh', onClick: () => retry() } : undefined
          }
        />
      </div>
    </div>
  </Modal>
)
