import { onError } from '@apollo/client/link/error'

export const getErrorLink = (config, logError) =>
  onError((error) => {
    const { graphQLErrors, networkError } = error
    if (graphQLErrors) {
      for (let { message, path } of graphQLErrors) {
        logError(`GraphQL error: ${message}`, 'warning', {
          captureContext: (scope) => {
            return scope.setTags({
              path
            })
          }
        })
      }
    }

    if (networkError) {
      logError(networkError.message, 'warning')
    }
  })
