import { useMemo } from 'react'
import { getItemDetails } from '@local/modifier-form-helpers'
import { useItemDetailsQuery } from '@local/menu-queries'
import { MenuItemDetails } from '@local/do-secundo-model'

const getBasePrice = (data = {}) =>
  (data?.menuItemDetails && data?.menuItemDetails.price) || 0

export const useGetItemDetails = ({
  itemGuid,
  itemGroupGuid,
  shortUrl,
  restaurantGuid,
  visibility,
  dateTime,
  queryOptions
}) => {
  const { loading, error, data } = useItemDetailsQuery({
    itemGuid,
    itemGroupGuid,
    restaurantGuid,
    shortUrl,
    visibility,
    dateTime,
    queryOptions
  })

  const enhancedItemDetails = getItemDetails(data, {
    itemGuid,
    itemGroupGuid,
    restaurantGuid
  })

  const itemDetails = useMemo(
    () =>
      enhancedItemDetails ? new MenuItemDetails(enhancedItemDetails) : null,
    [enhancedItemDetails]
  )

  const basePrice = getBasePrice(data)

  return { loading, error, data, itemDetails, basePrice }
}
