import { useQuery } from '@apollo/client'
import { VisibilityEnum } from '../types'
import { MENU_ITEM_DETAILS } from '../graphql'

// this should be autogenerated from the graphql but do this for now
interface Input {
  restaurantGuid: string
  shortUrl: string
  itemGuid: string
  itemGroupGuid: string
  // ignored for now until we plumb through bff
  visibility: VisibilityEnum
  dateTime?: string | null
  skip?: boolean
  queryOptions: object
}

export const useItemDetailsQuery = ({
  restaurantGuid,
  shortUrl,
  itemGuid,
  itemGroupGuid,
  visibility = 'TOAST_ONLINE_ORDERING',
  dateTime,
  queryOptions
}: Input) => {
  const { loading, error, data } = useQuery(MENU_ITEM_DETAILS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        restaurantGuid,
        shortUrl,
        itemGuid,
        itemGroupGuid,
        visibility,
        dateTime: dateTime || undefined,
        menuApi: 'DO'
      }
    },
    ...queryOptions
  })

  return { loading, error, data }
}
