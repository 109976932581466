import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { getTransformedDoMenuItemData } from '@local/pricingutility'
import { useDoMenusItemDetailsQuery } from '@local/menu-queries'

const dataByTypename = (data) =>
  data && data.__typename ? { [data.__typename]: data } : {}

/**
 * Helper hook that gets DO_MENU_ITEM_DETAILS and returns it along with helper maps(raw data is presented in the form of reference arrays and is turned into objects for faster look up).
 * @param queryInput - restaurantGuid
 * @param {string} queryInput.restaurantGuid restaurantGuid
 * @param {string} queryInput.itemGuid itemGuid
 * @param {string} queryInput.itemGroupGuid itemGroupGuid
 * @param {string} queryInput.visibility visibility
 * @param {string?} queryInput.dateTime fulfillment date and time
 * @param {object} queryOptions - standard options object to be passed into DO_MENU_ITEM_DETAILS query (https://www.apollographql.com/docs/react/data/queries/)
 * @returns {{data, error, loading, transformedMenuData}} - DO_MENU_ITEM_DETAILS data as well as transformed maps of groups/modifiers from pricingUtility
 */

export const useGetDoMenusItemDetails = ({
  restaurantGuid,
  itemGuid,
  itemGroupGuid,
  visibility,
  dateTime,
  queryOptions
}) => {
  const { loading, error, data } = useDoMenusItemDetailsQuery({
    restaurantGuid,
    itemGuid,
    itemGroupGuid,
    visibility,
    dateTime,
    queryOptions
  })

  const menuItemResponse = data ? data['doMenus_findMenuItem'] : null

  const { DoMenus_FindMenuItemError, DoMenus_FindMenuItemResponse } =
    dataByTypename(menuItemResponse)
  // ensures we dont recalculate it on adding/editing item
  const transformedMenuData = useMemo(
    () => getTransformedDoMenuItemData(DoMenus_FindMenuItemResponse),
    [DoMenus_FindMenuItemResponse]
  )

  return {
    data: DoMenus_FindMenuItemResponse,
    error: DoMenus_FindMenuItemError || error,
    loading,
    transformedMenuData
  }
}

useGetDoMenusItemDetails.propTypes = {
  restaurantGuid: PropTypes.string.isRequired,
  itemGuid: PropTypes.string.isRequired,
  itemGroupGuid: PropTypes.string.isRequired,
  queryOptions: PropTypes.object
}
