import PropTypes from 'prop-types'
import { getIn } from 'formik'
import { Model } from './Model'
import { ModifierGroup, ModifierGroupModel } from './ModifierGroup'

export interface ModifierModel {
  name: string
  modifierGroups?: ModifierGroup[]
  price?: number
  itemGuid: string
  isSelected: boolean
  quantity?: number
  allowsDuplicates?: boolean
  selected?: any
}

export class Modifier extends Model implements ModifierModel {
  private parent: ModifierGroup
  private groupIndex: number
  private defaultSelected: any
  private _isSelected: boolean
  private _formikValues: any

  name: string
  modifierGroups?: ModifierGroup[]
  price?: number
  itemGuid: string
  quantity?: number
  allowsDuplicates?: boolean
  selected?: any
  isCurrent: boolean

  constructor(
    modifier: ModifierModel,
    parent: ModifierGroup,
    groupIndex: number
  ) {
    super(modifier)
    this.groupIndex = groupIndex
    this.parent = parent
    this.modifierGroups = this.mapModifierGroups(modifier.modifierGroups || [])
    this.name = modifier.name
    this.itemGuid = modifier.itemGuid
    this.selected = modifier.selected
    this.defaultSelected = this.selected

    this.formikValues = null

    this._isSelected = this.defaultSelected

    this.isCurrent = false

    // need to check modifier.selected to apply quantity for default selections
    this.quantity = modifier.quantity || (modifier.selected && 1) || 0

    this.allowsDuplicates = modifier.allowsDuplicates
  }

  /**
   * Normalize the menu item schema.
   */
  get guid() {
    return this.itemGuid
  }

  mapModifierGroups(groups: ModifierGroupModel[]): ModifierGroup[] {
    return groups.map((modifierGroup) => new ModifierGroup(modifierGroup, this))
  }

  getNextValues(): ModifierGroup[] | undefined {
    return this.modifierGroups
  }

  resetDefaults() {}

  getIn(values: any) {
    return getIn(values, this.deepFieldNameByGuid)
  }

  /**
   * @returns {boolean} True if this modifier has nested modifiers.
   */
  get hasNested(): boolean {
    return Boolean(this.modifierGroups?.length)
  }

  get deepPrefix(): string {
    return this.parentModifier
      ? `${this.parentModifier.deepFieldNameByGuid}.`
      : ''
  }

  /**
   * @returns If the group is single select return the group key
   *   else return the modifier's formik key along with the group key.
   */
  get valueFieldName(): string {
    const baseKey = this.parent.valueFieldName
    return this.parent.isSingleSelect
      ? baseKey
      : `${baseKey}.${this.groupIndex}.selected`
  }

  /**
   * Returns a reference to the quantity field for use with fields that can have duplicate modifier quantities
   * @returns
   */
  get valueFieldNameQuantity(): string {
    return `${this.parent.valueFieldName}.${this.groupIndex}.quantity`
  }

  get deepValueFieldName(): string {
    return `${this.deepPrefix}${this.valueFieldName}`
  }

  /**
   * Returns a reference to the quantity field for use with fields that can have duplicate nested modifier quantities
   * @returns
   * */
  get deepValueFieldNameQuantity(): string {
    return `${this.deepPrefix}${this.valueFieldNameQuantity}`
  }

  get fieldNameByGuid(): string {
    return `${this.parent.fieldName}.detailsByItemGuid.${this.guid}`
  }

  get deepFieldNameByGuid(): string {
    return `${this.deepPrefix}${this.fieldNameByGuid}`
  }

  /**
   * @returns The guid of this modifier's parent group.
   */
  get groupGuid(): string {
    return this.parent.guid
  }

  /**
   * @returns The parent modifier of which this
   *   modifier's group is child to or undefined if this is the top
   *   level modifier.
   */
  get parentModifier(): Modifier | null {
    return this.parent.parentModifier
  }

  /**
   * Set's the formik model to true and stores the selected state
   * locally.
   */
  setSelected(setFieldValue: Function, value: any, isDeep = false) {
    const valueField = isDeep ? this.deepValueFieldName : this.valueFieldName
    if (this.parent.isSingleSelect) {
      // Value can be a boolean or a guid
      const guidValue =
        typeof value === 'boolean' ? (value ? this.guid : '') : value
      this.parent.selectedGuid = guidValue
      setFieldValue(valueField, guidValue)
    } else {
      this._isSelected = value
      setFieldValue(valueField, value)
    }
  }

  /**
   * Set's the formik model quantity stores the selected state
   * locally.
   */
  setModifierQuantity(
    setFieldValue: Function,
    value: any,
    isDeep: boolean = false
  ) {
    const valueField = isDeep
      ? this.deepValueFieldNameQuantity
      : this.valueFieldNameQuantity
    if (this.parent.isSingleSelect) {
      // Value can be a boolean or a guid
      const guidValue =
        typeof value === 'boolean' ? (value ? this.guid : '') : value
      this.parent.selectedGuid = guidValue
      setFieldValue(valueField, guidValue)
    } else {
      this._isSelected = value > 0
      setFieldValue(valueField, value)
    }
  }

  get selectedValue(): string | boolean {
    return this.parent.isSingleSelect ? this.guid : this.isSelected
  }

  get isSelected(): boolean {
    if (this.parent.isSingleSelect) {
      return this.guid === this.parent.selectedGuid
    }
    return this._isSelected
  }

  set formikValues(values: any) {
    this._formikValues = values
  }

  get formikValues(): any {
    return this._formikValues
  }
}

// Keep for now as some js files rely on this
export const ModifierShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  modifierGroups: PropTypes.array.isRequired,
  price: PropTypes.number,
  itemGuid: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  quantity: PropTypes.number,
  allowsDuplicates: PropTypes.bool
})
