import React from 'react'
import cx from 'classnames'

import { FormatCurrency } from '@local/format-currency'
import { ExpandMoreIcon } from '@toasttab/buffet-pui-icons'
import styles from './ModifierFieldLabel.module.css'

export interface ModifierFieldLabelProps {
  name: string
  price?: number
  hasNested?: boolean
  outOfStock?: boolean
  nestedModifiersNames?: string[]
}

export const ModifierFieldLabel = ({
  name,
  price,
  hasNested,
  outOfStock = false,
  nestedModifiersNames = []
}: ModifierFieldLabelProps) => {
  const hasPrice = Boolean(price)

  const aside = outOfStock ? (
    <span
      data-testid='modifiers-details'
      className={cx(styles.modifierDetails, styles.outOfStock, 'type-default')}
    >
      -Out of stock-
    </span>
  ) : (
    (hasPrice || hasNested) && (
      <div
        data-testid='modifiers-details'
        className={cx(styles.modifierDetails, 'type-default')}
      >
        {hasPrice && (
          <span
            data-testid='modifiers-price'
            className={cx(styles.modifierPrice, 'type-default')}
          >
            <FormatCurrency showLeadingSign amount={price} />
          </span>
        )}
        {hasNested && (
          <span
            data-testid='modifiers-has-nested-indicator'
            className={`${styles.modifierMore} text-primary-75`}
          >
            <ExpandMoreIcon size='md' className='fill-current' />
          </span>
        )}
      </div>
    )
  )
  return (
    <div className={styles.modifier}>
      <div data-testid='modifierDescription'>
        <div className={'type-default'}>{name}</div>
        {nestedModifiersNames.length > 0 && (
          <div
            data-testid='modifiers-nested-selections'
            className={styles.nestedSelections}
          >
            {nestedModifiersNames.join(', ')}
          </div>
        )}
      </div>
      {aside}
    </div>
  )
}
