import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { getErrorLink, getHttpLink } from './links'
import { trimEnd } from 'lodash'
import { envFromOrderHostname } from '@toasttab/do-secundo-env-from-orders-hostname'

const ooGlobals = window.OO_GLOBALS || {}

const env = envFromOrderHostname(window.location.hostname)
// TODO Need to change this to support sandbox once the BFF can be deployed there.
const prefix = env === 'prod' ? 'ws-api' : 'ws-preprod-api.eng'

export const apolloConfig = {}
apolloConfig.RESTAURANT_GUID = ooGlobals.restaurantGuid || ''

const GATEWAY_BASE_URI = trimEnd(
  process.env.GATEWAY_BASE_URI ||
    ooGlobals.gatewayBaseUri ||
    `https://${prefix}.toasttab.com`,
  '/'
)

const BFF_BASE_URI = trimEnd(
  process.env.BFF_BASE_URI ||
    ooGlobals.bffBaseUri ||
    `${GATEWAY_BASE_URI}/do-federated-gateway/v1`,
  '/'
)

apolloConfig.BFF_BASE_URI = BFF_BASE_URI
apolloConfig.cache = new InMemoryCache()

const linkFactories = [getErrorLink, getHttpLink]

const getApolloClientOptionsLink = (apolloLinks, logger) =>
  ApolloLink.from(apolloLinks.map((fn) => fn(apolloConfig, logger)))

export const getApolloClient = (logger) => {
  return new ApolloClient({
    cache: apolloConfig.cache,
    name: process.env.PKG_NAME,
    version: process.env.PKG_VERSION,
    link: getApolloClientOptionsLink(linkFactories, logger)
  })
}
