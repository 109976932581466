import * as React from 'react'
import { Form, Formik } from 'formik'
import { Modal } from '@local/do-secundo-modal'
import {
  NestedModifiersProvider,
  ModifiersFooter,
  ModifiersHeader,
  ModifierBodyPricing
} from '@local/do-secundo-modifiers'
import { PanelStack, PanelProvider } from '@local/do-secundo-panel-stack'
import { ItemProvider } from '@local/do-secundo-item-provider'
import { ItemModifierModalError } from '../ItemModifierModalError'

export const ItemModifierModal = ({
  formikProps,
  handleSubmit,
  getModifierGroups,
  doItemDetailsData,
  transformedMenuData,
  itemDetails,
  getDisplayPrice,
  quantityInput,
  getSelectedNames,
  fractionalQuantityInput,
  isEdit,
  orderingAvailable,
  specialRequestsConfig,
  onClose = () => {},
  error,
  retries,
  resetRetries
}) => {
  const [modalBody, setModalBodyFromModal] = React.useReducer(
    (_, modalComponent) => {
      if (modalComponent) {
        return modalComponent.modalBodyRef.current
      }
    },
    undefined
  )

  return (
    <Formik {...formikProps} onSubmit={handleSubmit}>
      <PanelProvider>
        <ItemProvider>
          <NestedModifiersProvider
            doMenuItemDetails={doItemDetailsData}
            getModifierGroups={getModifierGroups}
            transformedMenuData={transformedMenuData}
          >
            <Modal
              header={<ModifiersHeader itemDetails={itemDetails} />}
              wrapper={(children) => <Form>{children}</Form>}
              onClose={onClose}
              footer={
                <ModifiersFooter
                  itemDetails={itemDetails}
                  getDisplayPrice={getDisplayPrice}
                  onClose={onClose}
                  quantityInputField={quantityInput}
                  validationSchema={formikProps?.validationSchema}
                  orderingAvailable={orderingAvailable}
                  isEditModal={isEdit}
                />
              }
              ref={setModalBodyFromModal}
            >
              <PanelStack panelBody={modalBody}>
                <ModifierBodyPricing
                  transformedMenuData={transformedMenuData}
                  doMenuItemDetails={doItemDetailsData}
                  getModifierGroups={getModifierGroups}
                  itemDetails={itemDetails}
                  getSelectedNames={getSelectedNames}
                  quantityInputField={fractionalQuantityInput}
                  specialRequestsConfig={specialRequestsConfig}
                />
              </PanelStack>
              {error && (
                <ItemModifierModalError {...{ error, retries, resetRetries }} />
              )}
            </Modal>
          </NestedModifiersProvider>
        </ItemProvider>
      </PanelProvider>
    </Formik>
  )
}
