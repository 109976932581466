import * as React from 'react'
import { asyncScheduler } from 'rxjs'
import { observeOn } from 'rxjs/operators'
import { Currency } from '@toasttab/buffet-pui-number-utilities'
import { Locale } from '@toasttab/buffet-pui-locale-utilities'

import {
  editItemUserRequest$,
  fulfillment$,
  restaurant$
} from 'cornucopia-apis'

interface EditItemUserRequestData {
  itemGroupGuid: string
  itemGuid: string
  selectionGuid: string
  cartGuid: string
}

interface FulfillmentData {
  selected: boolean
  fulfillmentTime: string | null
}

interface SpecialRequestsConfig {
  enabled: boolean
  placeholderMessage: string
}

interface I18n {
  currency: Currency
  locale: Locale
  country: string
}

export interface Restaurant {
  orderingAvailable: boolean
  specialRequestsConfig?: SpecialRequestsConfig
  i18n?: I18n
}

interface SubscriptionsValuesContextShape {
  restaurant: Restaurant
  fulfillment: string | null
  editItemUserRequestData: EditItemUserRequestData
}

const defaultEditItemUserRequest = {
  itemGuid: '',
  itemGroupGuid: '',
  selectionGuid: '',
  cartGuid: ''
}

const useFulfillment = () => {
  const [fulfillment, setFulfillment] = React.useState<FulfillmentData>({
    selected: false,
    fulfillmentTime: null
  })

  React.useEffect(() => {
    const fulfillmentSubscription = fulfillment$.subscribe(
      (data: FulfillmentData) => {
        setFulfillment(data)
      }
    )

    return () => fulfillmentSubscription.unsubscribe()
  }, [])

  return fulfillment
}
export const useRestaurant = () => {
  const [restaurantConfig, setRestaurantConfig] =
    React.useState<Restaurant | null>(null)

  React.useEffect(() => {
    const restaurantSubscription = restaurant$.subscribe((data: any) => {
      setRestaurantConfig(data)
    })

    return () => restaurantSubscription.unsubscribe()
  }, [])

  return restaurantConfig
}

export const useEditItemUserRequest = (isEditMode: boolean) => {
  // null comes from cornucopia-api in case we land on /edit url
  // so we should not use it as a default value
  const [editItemUserRequestData, setEditItemUserRequestData] =
    React.useState<EditItemUserRequestData | null>(defaultEditItemUserRequest)

  React.useEffect(() => {
    if (!isEditMode) return
    const editItemSubscription = editItemUserRequest$
      // Explicitly make async so we don't render before parent
      .pipe(observeOn(asyncScheduler))
      .subscribe((data: EditItemUserRequestData | null) => {
        setEditItemUserRequestData(data)
      })

    return () => editItemSubscription.unsubscribe()
  }, [isEditMode])
  return editItemUserRequestData
}

const SubscriptionsValuesContext =
  React.createContext<SubscriptionsValuesContextShape>(
    {} as SubscriptionsValuesContextShape
  )

interface SubscriptionsValuesProviderProps {
  children: React.ReactNode
  isEditMode: boolean
  onClose: () => void
}
export const SubscriptionsValuesProvider = ({
  children,
  isEditMode,
  onClose
}: SubscriptionsValuesProviderProps) => {
  const restaurant = useRestaurant()
  const { fulfillmentTime, selected: fulfillmentSelected } = useFulfillment()
  const editItemUserRequestData = useEditItemUserRequest(isEditMode)

  // when landing on the page via /edit we wont have the request data thus need to close the app
  if (!editItemUserRequestData) {
    onClose()
    return null
  }

  // do not render the modal until we know if rx is open/closed to avoid a rerender
  if (!restaurant) {
    // return fragment to stay subscribed to react to incoming values
    return <></>
  }
  // check fulfillment selection to redirect user to fulfillment selection only if rx is open
  if (!fulfillmentSelected && restaurant.orderingAvailable) {
    // return fragment to stay subscribed to react to incoming values
    return <></>
  }

  const context = {
    restaurant,
    fulfillment: fulfillmentTime,
    editItemUserRequestData
  }

  return (
    <SubscriptionsValuesContext.Provider value={context}>
      {children}
    </SubscriptionsValuesContext.Provider>
  )
}

export const useSubscriptionsValues = () =>
  React.useContext(SubscriptionsValuesContext)
