import React from 'react'
import { getSelectionDetails } from '@local/modifier-form-helpers'
import { useSelectionDetailsQuery, Input } from '@local/menu-queries'
import { MenuItemDetails } from '@local/do-secundo-model'

// fixme generate type from apollo
const getBasePrice = (data: any = {}) =>
  (data?.selectionItemDetails && data?.selectionItemDetails.menuItemPrice) || 0

export const useGetSelectionDetails = (input: Input) => {
  const { itemGuid, itemGroupGuid, restaurantGuid, visibility } = input
  const { loading, error, data } = useSelectionDetailsQuery(input)

  const enhancedItemDetails = getSelectionDetails(data, {
    itemGuid,
    itemGroupGuid,
    restaurantGuid,
    visibility
  })

  const itemDetails = React.useMemo(
    () =>
      enhancedItemDetails ? new MenuItemDetails(enhancedItemDetails) : null,
    [enhancedItemDetails]
  )
  const basePrice = getBasePrice(data)

  return { loading, error, data, itemDetails, basePrice }
}
