import React, {
  useCallback,
  createContext,
  useState,
  useEffect,
  useContext
} from 'react'
import PropTypes from 'prop-types'
import { useFormikContext, Formik, getIn } from 'formik'

import { usePanelStack } from '@local/do-secundo-panel-stack'
import { modifierFormHelpers } from '@local/modifier-form-helpers'
import { ModifierBodyPricing } from '../ModifierBodyPricing/ModifierBodyPricing'

const NestedModifiersContext = createContext({})
const PUSH_ACTION = 'push'
const POP_ACTION = 'pop'

export const NestedModifiersProvider = ({
  children,
  doMenuItemDetails,
  getModifierGroups,
  transformedMenuData
}) => {
  const clearedNestedModifierState = () => {
    return { modifier: null }
  }
  const [nestedModifierState, setNestedModifierState] = useState(
    clearedNestedModifierState()
  )
  const { modifier, action } = nestedModifierState

  const [nestedModifierIsValid, setNestedModifierIsValid] = useState(false)

  const [modalLoading, setModalLoading] = useState(false)

  const { push, pop } = usePanelStack()

  const { setFieldValue, values } = useFormikContext()

  const pushNestedModifier = useCallback(
    (modifier) => {
      modifier.isCurrent = true

      const parentModifier = modifier ? modifier.parentModifier : null
      if (parentModifier) parentModifier.isCurrent = false

      return setNestedModifierState({ modifier, action: PUSH_ACTION })
    },
    [setNestedModifierState]
  )

  const popNestedModifier = useCallback(() => {
    if (modifier) modifier.isCurrent = false

    const parentModifier = modifier ? modifier.parentModifier : null
    if (parentModifier) parentModifier.isCurrent = true

    pop()
    return setNestedModifierState({
      modifier: parentModifier,
      action: POP_ACTION
    })
  }, [pop, modifier])

  const recordCurrentSelections = useCallback(
    (modifier) => {
      // This is required to set the previous model changes that occured
      // in formik.
      if (modifier.deepFieldNameByGuid) {
        setFieldValue(modifier.deepFieldNameByGuid, modifier.formikValues)
      }
    },
    [setFieldValue]
  )

  /**
   * Updates a nested modifier's value:
   * - updates selected property when modifier isSingleSelect otherwise updates quantity property
   * - newValue should only ever be 0 or 1 bc we do not support duplicate nested modifiers
   * - when we do support duplicate nested modifiers this code should still work as the stepper component
   * - should update values
   */
  const confirmNestedModifierSelections = useCallback(() => {
    if (modifier.parent.isSingleSelect) {
      modifier.setSelected(setFieldValue, true, true)
    } else {
      const currentValue = getIn(values, modifier.deepValueFieldNameQuantity)
      const newValue = currentValue > 0 ? currentValue : 1

      modifier.setModifierQuantity(setFieldValue, newValue, true)
    }

    recordCurrentSelections(modifier)
    popNestedModifier()
  }, [
    modifier,
    recordCurrentSelections,
    popNestedModifier,
    setFieldValue,
    values
  ])

  // Create a new inner component to make sure it rerenders with the values from
  // this scope.
  const InnerPanelForm = ({
    modifier,
    validate,
    validationSchema,
    getSelectedNames
  }) => {
    // This will have the top level formik values so this instance gets
    // updated on pop as opposed to keeping its stored values.
    const { values, setFieldValue } = useFormikContext()
    const nestedModifierFormikValues = modifier.getIn(values)

    let removeModifier
    // deepValueFieldName is based on if modifier parent isSingleSelect
    const fieldName = modifier.parent.isSingleSelect
      ? modifier.deepValueFieldName
      : modifier.deepValueFieldNameQuantity
    const isSelected = getIn(values, fieldName)

    if (!modifier.parent.isSingleSelect && isSelected) {
      removeModifier = () => {
        modifier.resetSubTreeDefaults()
        modifier.setModifierQuantity(setFieldValue, 0, true)
        popNestedModifier()
      }
    }

    // inner panel closure scope
    const recordCurrentInnerPanelSelections = () => {
      recordCurrentSelections(modifier)
    }

    return (
      <Formik
        initialValues={nestedModifierFormikValues}
        validateOnMount
        validate={validate}
        validationSchema={validationSchema}
      >
        <ModifierBodyPricing
          transformedMenuData={transformedMenuData}
          doMenuItemDetails={doMenuItemDetails}
          getModifierGroups={getModifierGroups}
          isNested
          itemDetails={modifier}
          getSelectedNames={getSelectedNames}
          onRemoveModifier={removeModifier}
          recordCurrentSelections={recordCurrentInnerPanelSelections}
        />
      </Formik>
    )
  }

  InnerPanelForm.propTypes = {
    modifier: PropTypes.object,
    validate: PropTypes.func,
    validationSchema: PropTypes.object,
    getSelectedNames: PropTypes.func
  }

  useEffect(() => {
    if (modifier && action === PUSH_ACTION) {
      const { schema: validationSchema, getSelectedNames } =
        modifierFormHelpers(modifier)

      // overwrites the validation callback
      // to also set nestedModfier form values and validity
      const validate = (newValues) => {
        const isValid = validationSchema.isValidSync(newValues)
        modifier.formikValues = newValues
        if (modifier.isCurrent) {
          // only update the valid state if this modifier is the current modifier
          setNestedModifierIsValid(isValid)
        }

        return isValid
      }

      push(
        <InnerPanelForm
          data-testid={`${modifier.guid}_MODIFIER`}
          modifier={modifier}
          validate={validate}
          validationSchema={validationSchema}
          getSelectedNames={getSelectedNames}
        />
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifier, action, push, values])

  return (
    <NestedModifiersContext.Provider
      value={{
        nestedModifier: modifier,
        pushNestedModifier,
        popNestedModifier,
        nestedModifierIsValid,
        confirmNestedModifierSelections,
        modalLoading,
        setModalLoading,
        recordCurrentSelections
      }}
    >
      {children}
    </NestedModifiersContext.Provider>
  )
}

/**
 * @typedef NestedModifiersContextProps
 * @property {any} nestedModifier
 * @property {Function} pushNestedModifier
 * @property {Function} popNestedModifier
 * @property {boolean} nestedModifierIsValid
 * @property {Function} confirmNestedModifierSelections
 * @property {boolean} modalLoading
 * @property {Function} setModalLoading
 * @property {Function} recordCurrentSelections
 * @returns {NestedModifiersContextProps}
 */
export const useNestedModifiers = () => useContext(NestedModifiersContext)

NestedModifiersProvider.propTypes = {
  children: PropTypes.node.isRequired
}
