import React from 'react'
import PropTypes from 'prop-types'
import { connect, getIn, Field } from 'formik'

import { Fieldset } from '@local/do-secundo-fieldset'
import { ErrorMessage, Textarea } from '@local/do-secundo-form'

const SpecialInstructionsComponent = ({
  label,
  maxLength,
  name,
  formik,
  placeholder,
  rows
}) => {
  const current = getIn(formik.values, name).length
  const msg = `${current} / ${maxLength}`
  const instructions = (
    <>
      <span
        data-testid='modifier-special-instructions-max-length'
        className={'type-default'}
      >
        {maxLength} characters or fewer
      </span>
      {current ? (
        <span data-testid='modifier-special-instructions-count'>{msg}</span>
      ) : null}
    </>
  )
  return (
    <Fieldset label={label} instructions={instructions} name={name}>
      <Field
        id='specialInstructions'
        maxLength={maxLength}
        rows={rows}
        name={name}
        placeholder={placeholder}
        component={Textarea}
      />
      <ErrorMessage name={name} />
    </Fieldset>
  )
}

SpecialInstructionsComponent.propTypes = {
  label: PropTypes.string,
  maxLength: PropTypes.number.isRequired,
  name: PropTypes.string,
  formik: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.number.isRequired
}

export const SpecialInstructions = connect(SpecialInstructionsComponent)
