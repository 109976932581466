import React, { useEffect, useRef } from 'react'

import { animated, useTransition } from 'react-spring/web.cjs'
import { usePanelStack } from './PanelProvider'

import * as AnimationStates from './panel-animation-states'

import styles from './PanelStack.module.css'

interface PanelStackParams {
  children: React.ReactNode
  panelBody: any
  animationDuration: number
}
/**
 * Animates panels in and out of a stack. Once animation is done
 * it cleans up the visible stack to only allow one panel in the DOM
 * at a time.
 *
 * This is to mitigate any potential side effects between elements
 * in the panels.
 */
export const PanelStack = ({
  children,
  panelBody = {},
  animationDuration = 250
}: PanelStackParams) => {
  const {
    stack,
    push,
    isEnterAnimating,
    animationState,
    setAnimationState,
    panelBodyRef
  } = usePanelStack() as any
  panelBodyRef.current = panelBody
  // Will use children as the first component in the stack
  // this helps decrease consumer's implementation overhead
  const initialChildren = useRef(children)
  useEffect(() => {
    if (React.isValidElement(initialChildren.current)) {
      push(<>{initialChildren.current}</>)
    }
  }, [push])

  useEffect(() => {
    if (
      animationState === AnimationStates.LEAVE_FINISH ||
      animationState === AnimationStates.ENTER_FINISH
    ) {
      setAnimationState(AnimationStates.COMPLETE)
    }
  }, [animationState, setAnimationState])

  const transitionDefs: any = {
    config: {
      duration: animationDuration
    },
    initial: {
      transform: 'translateX(0px)'
    },
    enter: () => async (next: Function) => {
      panelBody.scrollTop = 0
      setAnimationState(AnimationStates.ENTER_START)
      await next({ transform: 'translateX(0px)' })
      await next({ position: 'relative' })
      setAnimationState(AnimationStates.ENTER_FINISH)
    },
    leave: () => async (next: Function) => {
      setAnimationState(AnimationStates.LEAVE_START)
      panelBody.scrollTop = 0
      await next({
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        transform: 'translateX(100%)'
      })
      setAnimationState(AnimationStates.LEAVE_FINISH)
    },
    from: {
      position: 'absolute',
      top: 0,
      left: 0,
      transform: 'translateX(100%)',
      minHeight: panelBody.offsetHeight
    }
  }

  const transitions = useTransition(stack, (item) => item.key, transitionDefs)
  // top two panels are visible, all others are removed from the DOM
  const activePanels = transitions.slice(-2)

  // hide bottom panel after animation if neither is in a leaving state
  const hideBottomPanel =
    activePanels.length > 1 &&
    activePanels[0].state === 'update' &&
    activePanels[1].state === 'update'

  if (activePanels.length === 1 && stack[stack.length - 1]) {
    panelBody.scrollTop = stack[stack.length - 1].scrollTop
  }

  return (
    <div className={styles.body}>
      {activePanels.map(({ item: { children }, key, props }, idx) => {
        // Entering animation require us to manually remove the bottom panel.
        // Leave animations automatically remove the top panel after
        // the animation is done.
        const isBottomPanel = idx === 0 && activePanels.length > 1
        const hidePanel = hideBottomPanel && isBottomPanel && !isEnterAnimating
        const pointerEvents = isBottomPanel ? 'none' : undefined

        return (
          !hidePanel && (
            <animated.div
              className={styles.panel}
              aria-hidden={isBottomPanel}
              key={key}
              style={{ ...props, pointerEvents }}
            >
              {children}
            </animated.div>
          )
        )
      })}
    </div>
  )
}
