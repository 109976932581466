import * as React from 'react'
import cx from 'classnames'

import { onEnterOrSpace } from '@local/do-secundo-key-handler'
import PlusIcon from './plus-icon.svg'
import MinusIcon from './minus-icon.svg'

import styles from './Stepper.module.css'

interface StepperParams {
  onIncrement: () => void
  onDecrement: () => void
  value: number
  incrementDisabled?: boolean
  decrementDisabled?: boolean
  modifierQuantities?: boolean
  dataTestId: string
}

export const Stepper = ({
  onIncrement,
  onDecrement,
  value,
  incrementDisabled,
  decrementDisabled,
  modifierQuantities,
  dataTestId
}: StepperParams) => {
  const { modQty, stepper, button, quantity, modQtyIcon } = styles
  return (
    <div
      data-testid={`${dataTestId}-stepper`}
      className={cx(stepper, { [modQty]: modifierQuantities })}
    >
      <button
        className={button}
        disabled={decrementDisabled}
        type='button'
        onClick={onDecrement}
        onKeyDown={onEnterOrSpace((e: any) => {
          onDecrement()
          e.stopPropagation()
        })}
        data-testid='decrement'
        aria-label='Decrease quantity'
      >
        <MinusIcon className={cx({ [modQtyIcon]: modifierQuantities })} />
      </button>
      <span data-testid='item-quantity' className={quantity}>
        {value}
      </span>
      <button
        disabled={incrementDisabled}
        className={button}
        type='button'
        onClick={onIncrement}
        onKeyDown={onEnterOrSpace((e: any) => {
          onIncrement()
          e.stopPropagation()
        })}
        data-testid='increment'
        aria-label='Increase quantity'
      >
        <PlusIcon className={cx({ [modQtyIcon]: modifierQuantities })} />
      </button>
    </div>
  )
}
