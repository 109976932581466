import * as React from 'react'

import { onEnterOrSpace } from '@local/do-secundo-key-handler'
import { FormShapeProps, FieldShapeProps } from '../form-shared-types'
import { CheckboxIcon } from './CheckboxIcon'
import styles from './Checkbox.module.css'

interface CheckboxProps {
  onSelect?(event?: React.ChangeEvent<HTMLInputElement>): void
  field: FieldShapeProps
  form: FormShapeProps
  disabled?: boolean
  id: string
  label: React.ReactNode
  icon?: React.ComponentType
}

const Checkbox = ({
  onSelect,
  field: { name, value, onChange, onBlur },
  form: { setFieldValue, setFieldTouched },
  disabled,
  id,
  label,
  icon: Icon,
  ...props
}: CheckboxProps) => {
  const onModifierKeyDown = onEnterOrSpace(() => {
    if (!disabled) {
      const newValue = value ? 0 : 1
      setFieldValue(name, newValue)
    }
  })

  return (
    <div data-testid='checkbox-full' className={styles.inputGroup}>
      <input
        data-testid='checkbox-input'
        className={styles.input}
        name={name}
        id={id}
        type='checkbox'
        value={value}
        checked={value}
        onChange={(...a) => {
          if (onSelect) {
            onSelect(...a)
            return
          }
          setFieldTouched(name, true)
          onChange(...a)
        }}
        onBlur={onBlur}
        disabled={disabled}
        {...props}
      />
      <label
        data-testid='checkbox-data'
        className={styles.label}
        tabIndex={0}
        onKeyDown={onModifierKeyDown}
        htmlFor={id}
      >
        <div className={styles.checkbox}>
          <CheckboxIcon icon={Icon} value={value} />
        </div>
        {label}
      </label>
    </div>
  )
}

export { Checkbox, CheckboxProps }
