import React, { useState, useCallback } from 'react'

import { getMessage, getRetryMessage, getError } from './error-utils'

import { Notification } from '@local/do-secundo-notification'
import { Button } from '@local/do-secundo-button'
import { Modal } from '@local/do-secundo-modal'

import styles from './Error.module.css'

const DEFAULT_ACTION_CONTENT = 'Click here to retry'

interface GraphQLErrorFields {
  code?: string
  networkError?: any
  graphQLErrors?: any[]
  message?: string
  moreInfo?: {
    header: string
    message: string
  }
}

interface ErrorComponentParams {
  error: GraphQLErrorFields
  retry?: () => void
  isActionable?: boolean
  isCloseable?: boolean
  closeAction?: () => void
}

export const ErrorComponent = ({
  error,
  retry,
  isActionable,
  isCloseable,
  closeAction,
  ...props
}: ErrorComponentParams) => {
  const [isShowingMoreInfo, setShowMoreInfo] = useState(false)
  isActionable = Boolean(retry || isActionable)
  const showMoreInfo = useCallback(() => {
    setShowMoreInfo(true)
  }, [])
  const hideMoreInfo = useCallback(() => {
    setShowMoreInfo(false)
  }, [])
  const errObj = getError(error)
  const errObjWithFields = errObj as GraphQLErrorFields
  return (
    <>
      <Notification
        severity='error'
        isActionable={isActionable}
        actionContent={DEFAULT_ACTION_CONTENT}
        onAction={() => retry!()}
        isCloseable={isCloseable}
        onClose={closeAction}
        {...props}
      >
        <span data-testid='error-message'>
          {getMessage(errObj)}
          {errObjWithFields.moreInfo && (
            <div className={styles.moreInfo}>
              <Button
                onClick={showMoreInfo}
                data-testid='error-more-info-button'
              >
                Learn more
              </Button>
            </div>
          )}
          {!isActionable && (
            <>
              <br />
              {getRetryMessage(errObj) || 'Please try again'}
            </>
          )}
        </span>
      </Notification>
      {isShowingMoreInfo && errObjWithFields.moreInfo && (
        <Modal responsive={false} onClose={hideMoreInfo}>
          <div className={styles.modal}>
            <h1
              className={styles.header}
              data-testid='error-more-info-modal-header'
            >
              {errObjWithFields.moreInfo.header}
            </h1>
            <p
              className={styles.text}
              data-testid='error-more-info-modal-message'
            >
              {errObjWithFields.moreInfo.message}
            </p>
          </div>
        </Modal>
      )}
    </>
  )
}
