import * as React from 'react'
import { modifierFormHelpers } from '@local/modifier-form-helpers'

export const useModifierFormHelpers = ({
  itemDetails,
  itemGroupGuid,
  usesFractionalQuantity,
  fractionalQuantity
}) => {
  return React.useMemo(() => {
    if (!itemDetails) return {}

    const {
      schema,
      initialValues,
      getModifierGroups,
      getComputedPrice,
      getSelectedNames
    } = modifierFormHelpers({
      modifierGroups: itemDetails.modifierGroups,
      quantity: itemDetails.quantity,
      specialInstructions: itemDetails.specialRequest || '',
      itemGroupGuid,
      usesFractionalQuantity,
      fractionalQuantity
    })

    return {
      getComputedPrice,
      formikProps: {
        validationSchema: schema,
        initialValues,
        validateOnMount: true
      },
      getModifierGroups,
      getSelectedNames
    }
  }, [itemDetails, itemGroupGuid, usesFractionalQuantity, fractionalQuantity])
}
