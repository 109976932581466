import React from 'react'

import { Stepper } from '@local/do-secundo-stepper'
import styles from './ItemQuantity.module.css'

interface FormParams {
  errors: any
  touched: any
  setFieldValue: (name: string, value: number) => void
}

interface FieldParams {
  name: string
  value: number
  onChange: () => void
  onBlur: () => void
}

interface ItemQuantityParams {
  field: FieldParams
  form: FormParams
  min?: number
  max?: number
}

export const ItemQuantity = ({
  field,
  form,
  min = 1,
  max = Infinity
}: ItemQuantityParams) => {
  const { setFieldValue } = form
  const { value, name } = field
  const handleDecrement = () => {
    setFieldValue(name, value - 1)
  }
  const handleIncrement = () => {
    setFieldValue(name, value + 1)
  }
  const decrementDisabled = value <= min
  const incrementDisabled = value >= max
  return (
    <div data-testid='item-quantity' className={styles.wrapper}>
      <Stepper
        onIncrement={handleIncrement}
        onDecrement={handleDecrement}
        value={value}
        incrementDisabled={incrementDisabled}
        decrementDisabled={decrementDisabled}
        dataTestId={'item-quantity'}
      />
      <div />
    </div>
  )
}
