import { track } from '@toasttab/do-secundo-analytics'

export const getQueryInput = (
  values: any,
  getModifierGroups: (values: any, itemQuantity: number) => any[]
) => {
  const { quantity, specialInstructions, fractionalQuantity } = values

  const itemQuantity = fractionalQuantity
    ? parseFloat(fractionalQuantity)
    : quantity

  const modifierGroups = getModifierGroups(values, itemQuantity)

  return {
    modifierGroups,
    specialInstructions,
    quantity: itemQuantity
  }
}

export const trackOnSubmit = (
  featuredItemsEnabled: boolean,
  popularItemsEnabled: boolean,
  item: {
    menuItem: { name: string; itemTags: { name: string; guid: string }[] }
  },
  referrer?: string | null
) => {
  if (featuredItemsEnabled && referrer?.includes('Featured')) {
    track('Featured Item Added', { name: item.menuItem.name })
  }
  if (popularItemsEnabled && referrer?.includes('Popular')) {
    track('Popular Item Added', { name: item.menuItem.name })
  }
}
