import * as React from 'react'

import { Checkbox } from '../Checkbox'
import { FormShapeProps, FieldShapeProps } from '../form-shared-types'
import { Stepper } from '@local/do-secundo-stepper'
import { getNumSelectedModifiersByGroupGuid } from '@local/modifier-form-helpers'
import ModifierQuantityCheckbox from './modifier-quantity-checkbox.svg'
import styles from './Multibox.module.css'

export interface MultiboxProps {
  field: FieldShapeProps
  form: FormShapeProps
  id: string
  label: React.ReactNode
  onSelect?(event?: React.ChangeEvent<HTMLInputElement>): void
  disabled?: boolean
  groupGuid: string
  maxSelections: number | null
  dataTestId: string
}

const Multibox = ({
  onSelect,
  field,
  form,
  disabled,
  id,
  label,
  maxSelections,
  groupGuid,
  dataTestId
}: MultiboxProps) => {
  const { setFieldValue } = form
  const { name, value } = field
  const numSelected = getNumSelectedModifiersByGroupGuid(form, groupGuid)
  const decrementDisabled = false // false bc we reset to 0 when value = 1 and decrement function is called
  const incrementDisabled = maxSelections
    ? numSelected === maxSelections
    : false

  const increment = () => {
    setFieldValue(name, value + 1)
  }

  const decrement = () => {
    if (value === 1) {
      setFieldValue(name, 0)
    }
    setFieldValue(name, value - 1)
  }

  return (
    <div
      data-testid={`${dataTestId}-multibox`}
      className={styles.multiboxComponent}
    >
      <div className={styles.checkboxWrapper}>
        <Checkbox
          onSelect={onSelect}
          field={field}
          form={form}
          disabled={disabled}
          id={id}
          icon={ModifierQuantityCheckbox}
          label={
            <>
              {label}
              {value > 0 && (
                <Stepper
                  value={value}
                  onDecrement={decrement}
                  onIncrement={increment}
                  decrementDisabled={decrementDisabled}
                  incrementDisabled={incrementDisabled}
                  modifierQuantities
                  dataTestId={dataTestId}
                />
              )}
            </>
          }
        />
      </div>
    </div>
  )
}

Multibox.displayName = 'Multibox'

export { Multibox }
