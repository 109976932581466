import * as React from 'react'
import cx from 'classnames'
import { Loading } from '@local/do-secundo-loading'
import { CartIcon } from '@toasttab/buffet-pui-icons'

import styles from './PriceTransparencyCartButton.module.css'

export interface PriceTransparencyCartButtonProps {
  loading: boolean
  disabled: boolean
  'data-testid'?: string
}

export const PriceTransparencyCartButton = ({
  loading,
  disabled = false,
  ...props
}: PriceTransparencyCartButtonProps) => {
  if (loading) {
    return (
      <button
        className={cx(
          styles.cartButton,
          'border-0 font-semibold flex justify-center mx-0 my-2 px-4type-default rounded',
          {
            [styles.loading]: loading
          }
        )}
        type='submit'
        disabled
        {...props}
      >
        <Loading />
      </button>
    )
  }

  const buttonContent = (
    <span
      className={cx(styles.cartButtonWrapper, 'items-center mr-3 type-default')}
    >
      <span className='h-6 w-6 mr-2'>
        <CartIcon />
      </span>
      Add to Cart
    </span>
  )

  return (
    <button
      className={cx(
        styles.cartButton,
        'border-0 font-semibold flex justify-center mx-0 my-2 px-4 type-default rounded'
      )}
      type='submit'
      disabled={disabled}
      {...props}
    >
      {buttonContent}
    </button>
  )
}
