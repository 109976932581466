import * as React from 'react'
import { ApolloProvider } from '@apollo/client'
import { getApolloClient } from '../apollo/apollo-client'

import { ItemModifierModalWrapper } from '@local/item-modifiers'
import { SubscriptionsValuesProvider } from '@local/subscriptions-values-provider'
import { MenuDataProvider } from '@local/menu-data-provider'
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import {
  OOGlobalsProvider,
  getSpaVersion
} from '@local/do-secundo-oo-globals-provider'
import { useTrackPreviousLocation } from '@local/use-track-previous-location'
import { ExperimentsProvider } from '../../components/ExperimentsProvider/ExperimentsProvider'
import { AppProps, ItemModifierProps } from './types'
import { useSentry } from 'banquet-runtime-modules'

const spaVersion = getSpaVersion()

const ItemModifierWrapper = ({
  shortUrl,
  restaurantGuid,
  menuVisibility,
  isEdit,
  onClose
}: ItemModifierProps) => {
  const { itemGuid, itemGroupGuid } = useParams()
  const [searchParams] = useSearchParams()
  const groupName = searchParams.get('referrer')
  const urlParams = { itemGuid, itemGroupGuid, groupName }

  return (
    <ExperimentsProvider>
      <SubscriptionsValuesProvider
        onClose={onClose}
        isEditMode={Boolean(isEdit)}
      >
        <MenuDataProvider
          urlParams={urlParams}
          shortUrl={shortUrl}
          restaurantGuid={restaurantGuid}
          menuVisibility={menuVisibility}
        >
          <ItemModifierModalWrapper onClose={onClose} />
        </MenuDataProvider>
      </SubscriptionsValuesProvider>
    </ExperimentsProvider>
  )
}

export function AppWrapper({
  shortUrl,
  restaurantGuid,
  ooGlobals,
  menuVisibility
}: AppProps) {
  return (
    <BrowserRouter basename={ooGlobals.routerBasename}>
      <App
        shortUrl={shortUrl}
        restaurantGuid={restaurantGuid}
        ooGlobals={ooGlobals}
        menuVisibility={menuVisibility}
      />
    </BrowserRouter>
  )
}

export function App({
  shortUrl,
  restaurantGuid,
  ooGlobals,
  menuVisibility
}: AppProps) {
  const prevLocation = useTrackPreviousLocation()
  const navigate = useNavigate()
  const { captureMessage } = useSentry()

  const onClose = React.useCallback(() => {
    navigate(prevLocation)
  }, [navigate, prevLocation])

  return (
    <ApolloProvider client={getApolloClient(captureMessage)}>
      <OOGlobalsProvider
        devError={ooGlobals.devError}
        enabledFeatureFlags={ooGlobals.enabledFeatureFlags}
        gatewayBaseUri={ooGlobals.gatewayBaseUri}
        toastwebBaseUri={ooGlobals.toastwebBaseUri}
        restaurantGuid={ooGlobals.restaurantGuid}
        routerBasename={ooGlobals.routerBasename}
        shortUrl={ooGlobals.shortUrl}
        spaVersion={spaVersion.current || ''}
        spaPreviousVersion={spaVersion.previous}
        mode={ooGlobals.mode}
        managementGroupGuid={ooGlobals.managementGroupGuid}
        mapboxAccessToken={ooGlobals.mapboxAccessToken}
        toastTakeoutCTAEnabled={Boolean(ooGlobals.toastTakeoutCTAEnabled)}
      >
        <Routes>
          <Route
            path='/add/:itemGuid/:itemGroupGuid'
            element={
              <div data-testid='menu-spa-add-item'>
                <ItemModifierWrapper
                  shortUrl={shortUrl}
                  restaurantGuid={restaurantGuid}
                  menuVisibility={menuVisibility}
                  onClose={onClose}
                  isEdit={false}
                />
              </div>
            }
          />
          <Route
            path='/edit'
            element={
              <div data-testid='menu-spa-edit-item'>
                <ItemModifierWrapper
                  shortUrl={shortUrl}
                  restaurantGuid={restaurantGuid}
                  menuVisibility={menuVisibility}
                  onClose={onClose}
                  isEdit={true}
                />
              </div>
            }
          />
          <Route path='/' element={<div data-testid='menu-spa-noop'></div>} />
        </Routes>
      </OOGlobalsProvider>
    </ApolloProvider>
  )
}
